<template>
  <div class=" justify-start content-start h-screen bg-red-100 w-full  flex flex-wrap">
    <!-- Menú Móvil Superior -->
    <div class="  lg:hidden xl:hidden top-0 left-0 w-full z-30  h-auto ">
      <nav class="sticky top-0 z-10 block w-full min-w-full px-4 py-2 text-white bg-gray-100 border rounded-none shadow-md h-max border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4">
        <div class="flex items-center justify-between text-blue-gray-900 h-12 bg-gray-100 ">
          <a href="#" class="mr-4 block cursor-pointer py-1.5 font-sans text-base font-medium leading-relaxed text-black antialiased">
            Rifas popcing
          </a>
          <div @click="toggleMobileMenu()"
          class="select-none rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
          <ul role="menu" v-if="mobileMenuVisible" 
          class="bg-white absolute mt-5 -ml-24 z-10 min-w-[180px] overflow-auto rounded-md border border-blue-gray-50  p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none">
         
          <template v-for="(menuItem, index) in menuItems" :key="index">
          <li @click="router.push(menuItem.route) " v-if="menuItem.permisos.includes(user.getAll().role)" role="menuitem" 
            class="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start  transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 text-black">
            {{ menuItem.title }}
          </li>
        </template>
        </ul>
        Menu
        </div>
       
          <!-- ... (Otros elementos del menú superior) ... -->
        </div>
      </nav>
    </div>

    <!-- Menú Lateral (Slide) -->
    <div class=" hidden lg:flex xl:flex h-[100vh] w-1/6 max-w-1/6 flex-col rounded-xl bg-clip-border p-4 text-gray-700 shadow-xl shadow-blue-gray-900/5 bg-gray-100">
      <div class="p-4 mb-2">
        <h5 class="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
          Rifas popcing
        </h5>
      </div>
      <nav class="flex min-w-[240px] flex-col gap-1 p-2 font-sans text-base font-normal text-blue-gray-700">
        <template v-for="(menuItem, index) in menuItems" :key="index">
          <div role="button" @click="router.push(menuItem.route)"  v-if="menuItem.permisos.includes(user.getAll().role)" class=" flex items-center w-full p-3 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900" href="#">

              <div class="grid mr-4 place-items-center">

              </div>
              {{ menuItem.title }}
              <div class="grid ml-auto place-items-center justify-self-end">
                  <div class="relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-full select-none whitespace-nowrap bg-blue-gray-500/20 text-blue-gray-900">
                      <span class=""></span>
                  </div>
              </div>

          </div>
          
        

      </template>

      </nav>
    </div>

    <!-- Contenido -->
    <div class="  md:w-full xl:w-5/6 lg:w-5/6  sm:max-w-5/6 md:max-w-5/6 xl:max-w-5/6 lg:max-w-5/6 xl:max-w-5/6  h-[100vh] overflow-scroll flex flex-wrap z-0">
      
      <RouterView />
    </div>
  </div>
</template>


<script>
import {
    ref
} from 'vue';
import {
    useRouter,
    useRoute
} from 'vue-router'

import {
    useUserStore
} from '../stores/user'
export default {
    setup() {
      const user = useUserStore()
        const salir = () => {

            router.push('/login')
            user.limpiar({})
        }
        const mobileMenuVisible = ref(false);
        

        const toggleMobileMenu = () => {
            mobileMenuVisible.value = !mobileMenuVisible.value;
        };

        const router = useRouter()
        const route = useRoute()

        const menuItems = [{
                route: "/app/welcome",
                title: "Bienvenido",
                icon: "la-home",
                permisos: ['creador_rifas','admin']
            },
            /*{
                title: 'Perfil',
                route: '/app/perfil',
                active: false,
                child: false,
                icon: 'la-planet',
                permisos: ['admin']
            },*/
            {
                title: 'Dominios',
                route: '/app/dominios',
                active: false,
                child: false,
                icon: 'la-planet',
                permisos: ['creador_rifas','admin']
            },
            {
                title: 'Rifas',
                route: '/app/rifas',
                active: false,
                child: false,
                icon: 'la-paper-plane',
                permisos: ['creador_rifas','admin']
            },

            {
                title: 'Clientes',
                route: '/app/clientes',
                active: false,
                child: false,
                icon: 'la-user-friends',
                permisos: ['creador_rifas','vendedor', 'admin']
            },

            {
                title: 'Usuarios',
                route: '/app/usuarios',
                active: false,
                child: false,
                icon: 'la-user-plus',
                permisos: ['admin']
            },

        ]

        return {
          user,
            router,
            route,
            menuItems,
            salir,
            mobileMenuVisible,
            toggleMobileMenu
        };
    },
};
</script>
