

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 GET_PLANES: async function( data){
  let url='/planes/'
  return initmixin.formatrespuesta('get', data,url,true)
},
 GET_DATA: async function( data){
      let url='/perfil/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    ACTUALIZAR_TK: async function( data) {
      let url='/perfil/tk/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
    ACTUALIZAR_USER: async function( data) {
      let url='/perfil/username/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
   
   


}