<template>
<div class="h-screen w-full  ">
    <div class="sticky top-0 w-full z-30  h-12 px-5 py-2 bg-green-700 shadow-sm shadow-gray-300">
        <div v-if="info.hasOwnProperty('logo_favicon')" @click="$router.push('/')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
            <img class="w-10 h-10 rounded-full" :class="info.color_logo" :src="info.logo_favicon" :alt="info.nombre" />
        </div>

    </div>
    <div v-if="not" class="min-w-screen min-h-screen bg-gray-50 py-5">

        <section class="py-20 bg-gray-100 w-full">
            <div class="container mx-auto px-4 w-full">
                <div class="p-8 lg:p-20 bg-white">
                    <h2 class="mb-20 text-5xl font-bold font-heading">Tus boletos seleccionados</h2>
                    <div class="flex flex-wrap items-center -mx-4">
                        <div class="w-full xl:w-7/12 mb-8 xl:mb-0 px-4">
                            <!--<div class="hidden lg:flex w-full">
                                <div class="w-full lg:w-3/6">
                                    <h4 class="mb-6 font-bold font-heading text-gray-500">Descripcion</h4>
                                </div>
                                <div class="w-full lg:w-1/6">
                                    <h4 class="mb-6 font-bold font-heading text-gray-500">Prcio</h4>
                                </div>
                                <div class="w-full lg:w-1/6 text-center">
                                    <h4 class="mb-6 font-bold font-heading text-gray-500">Cantidad</h4>
                                </div>
                                <div class="w-full lg:w-1/6 text-center">
                                    <h4 class="mb-6 font-bold font-heading text-gray-500">Eliminar</h4>
                                </div>

                            </div>-->
                            <div class="mb-12 py-6 border-t border-b border-gray-200">
                                <div v-for="(row,index) in boletos_compra" v-bind:key="index" class="flex flex-wrap items-center -mx-4 mb-6 md:mb-3">
                                    <div class="w-full md:w-4/6 lg:w-6/12 px-4 mb-6 md:mb-0">
                                        <div class="flex -mx-4 flex-wrap items-center">
                                            <div class="w-full md:w-1/3 px-4 mb-3">
                                                <div v-if="rifa_data.files.length>0"  class="flex items-center justify-center w-full md:w-24 h-32 bg-gray-100">
                                                    <img class="h-full object-contain" :src="rifa_data.files[0].url" alt="">
                                                </div>
                                            </div>
                                            <div class="w-2/3 px-4">
                                                <h3 class="mb-2 text-xl font-bold font-heading">Boleto #{{row}} </h3>
                                                <p class="text-gray-500"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hidden lg:block lg:w-1/12 px-4">
                                        <p class="text-lg text-blue-500 font-bold font-heading">x1</p>
                                        <span class="hidden text-xs text-gray-500 line-through"></span>
                                    </div>
                                    <div class="w-auto md:w-1/6 lg:w-2/12 px-4">
                                        <div class="inline-flex items-center px-4 font-semibold font-heading text-gray-500 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md">

                                            <p class="text-lg text-green-500 font-bold font-heading">${{formatPrice(Number(rifa_data.precio).toFixed(2))}}</p>

                                        </div>
                                    </div>
                                    <div class="hidden lg:block lg:w-2/12 px-4">
                                        <div @click="addOrRemove(row)" class="text-red-500 h-10 w-10 rounded-full text-center border border-red-500 hover:bg-red-200 cursor-pointer content-center flex flex-wrap justify-center text-xl font-extrabold ">
                                            <i class="las la-trash-alt"></i></div>

                                    </div>
                               

                            </div>

                        </div>
                        <!--<div class="flex flex-wrap items-center lg:-mb-4">
                            <span class="mr-12 mb-4 font-medium">Apply discount code:</span>
                            <input class="flex-1 md:flex-none mr-6 sm:mr-0 md:mr-6 mb-4 px-8 py-4 placeholder-gray-800 font-bold font-heading border rounded-md" type="text" placeholder="SUMMER30X">
                            <a class="flex-1 md:flex-none inline-block mb-4 px-8 py-4 text-center text-white font-bold font-heading uppercase bg-gray-800 hover:bg-gray-700 rounded-md" href="#">Apply</a>
                        </div>-->
                    </div>
                    <div class="w-full xl:w-5/12 px-4">
                        <div class="p-6 md:p-12 bg-green-600">
                            <h2 class="mb-6 text-4xl font-bold font-heading text-white">Compra</h2>
                            <div class="flex mb-8 items-center justify-between pb-5 border-b border-blue-100">
                                <span class="text-blue-50">Total</span>
                                <span class="text-xl font-bold font-heading text-white">${{formatPrice(Number(Number(rifa_data.precio)*Number(boletos_compra.length)).toFixed(2))}} MXN</span>
                            </div>
                            <h4 class="mb-2 text-xl font-bold font-heading text-white">Datos de contacto</h4>
                            <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
                                <!-- text - start -->
                                <div class="mb-10 md:mb-16">
                        

                                    <p class="max-w-screen-md text-gray-200 md:text-lg text-center mx-auto">Esta información es rquerida para poder contactar a la persona ganadora.</p>
                                </div>
                                <!-- text - end -->

                                <!-- form - start -->
                                <form class="max-w-screen-md grid sm:grid-cols-2 gap-4 mx-auto mb-4">
                                    <div class="flex flex-wrap">
                                        <label for="first-name" class="inline-block text-gray-800 text-left text-sm sm:text-base mb-2">Nombre*</label>
                                        <input name="first-name" v-model="nombre" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
                                        <span class="text-white  font-extrabold text-xs ">{{msgnombre}}</span>
                                    </div>

                                    <div class="flex flex-wrap">
                                        <label for="last-name" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Apellido*</label>
                                        <input name="last-name" v-model="apellido" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
                                        <span class="text-white  font-extrabold text-xs ">{{msgapellido}}</span>
                                    </div>

                                    <div class="sm:col-span-2 flex flex-wrap">
                                        <label for="company" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Teléfono*</label>
                                        <input name="company" v-model="telefono" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
                                        <span class="text-white  font-extrabold text-xs ">{{msgtelefono}}</span>
                                    </div>

                                    <div class="sm:col-span-2 flex flex-wrap">
                                        <label for="email" class="inline-block text-gray-800 text-sm sm:text-base mb-2">Correo*</label>
                                        <input name="email" v-model="correo" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
                                        <span class="text-white  font-extrabold text-xs ">{{msgcorreo}}</span>
                                    </div>
                                    <a @click="verified()" class="block w-full py-4 bg-green-800 hover:bg-green-400 text-center text-white font-bold font-heading uppercase rounded-md transition duration-200" >Pagar</a>
                                    <!--<p class="text-gray-400 text-xs">Politica de rembolso<a href="#" class="hover:text-blue-800 active:text-blue-600 underline transition duration-100 mx-2">Privacy Policy</a>.</p>-->
                                </form>
                                <!-- form - end -->
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </section>

   

</div>
<section v-if="!not" class="p-5 h-full overflow-y-scroll flex flex-wrap content-center py-10 pb-20">
    <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div class="max-w-md text-center content-center flex flex-wrap justify-center">
            <!--<h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
                     <span class="sr-only">Error</span>404
                 </h2>-->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-40 h-40 dark:text-gray-600">
                <path fill="currentColor" d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"></path>
                <rect width="176" height="32" x="168" y="320" fill="currentColor"></rect>
                <polygon fill="currentColor" points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"></polygon>
                <polygon fill="currentColor" points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"></polygon>
            </svg>
            <p class="text-2xl font-semibold md:text-3xl">La rifa ya no se encuentra disponible.</p>
            <p class="mt-4 mb-8 dark:text-gray-400">favor de regresar a la pagina de inicio para encontrar nuestras ultimas rifas.</p>
            <a rel="noopener noreferrer" href="/" class="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Regresar a inicio</a>
        </div>
    </div>
</section>

</div>
</template>

 
<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '../stores/config'

import rifas from "@/Services/rifas";
import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import {
    useInfoStore
} from '../stores/info'

import {
    useUserStore
} from '../stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    join
} from "path";
import {
    timePanelSharedProps
} from "element-plus/es/components/time-picker/src/props/shared";

export default {
    components: {

    },
    mixins: [rifas, generales],
    setup() {
        const router = useRouter()
        const route = useRoute()

        const info = useInfoStore()

        const config = useConfigStore();

        const salir = () => {
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,

            config,
            info: info.getAll(),
            infoset: info,

            salir

        }

    },
    data() {
        const user = useUserStore()

        return {
            drawer: false,
            activeTab: 1,
            user: user.getAll(),
            id: 0,
            /** nombre: 'Diego ',
             apellido:'delgado',
             telefono:'8714393131',
             correo:'diego.delgadob@gmail.com',**/
            nombre: '',
            apellido: '',
            telefono: '',
            correo: '',
            paginate: 200,
            descripcion: '',
            precio: '',
            boletos: '',
            boletos_sel: [],
            boletos_vendidos: [],
            activo: true,
            data: [],
            rifa_data: null,
            not: false,
            boletos_compra: [],
            msgnombre: '',
            msgtelefono: '',
            msgcorreo: '',
            msgapellido: ''

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {
        verified() {
            let totmsg = 0;
            if (this.nombre == '') {
                this.msgnombre = 'Nombre requerido';
                this.totmsg++;
            }
            if (this.apellido == '') {
                this.msgapellido = 'Apellido requerido';
                this.totmsg++;
            }
            if (this.telefono == '') {
                this.msgtelefono = 'Teléfono requerido';
                this.totmsg++;
            }
            if (this.correo == '') {
                this.msgcorreo = 'Correo requerido';
                this.totmsg++;
            }

            if (this.totmsg > 0) {

            } else {
                this.getButton();
            }

            emitter.emit("alerta_show", response.msg);

        },
        continuar() {

            let join_boletos = this.boletos_sel.join(',');
            this.$router.push('/informacion?b=' + join_boletos + '&bid=' + this.id)
        },

        addOrRemove(index) {

            this.boletos_compra = this.boletos_compra.filter(item => item != index)

        },

        async getData() {

            if (this.$route.query.hasOwnProperty('r')) {
                this.id = this.$route.query.r;

                if (this.$route.query.hasOwnProperty('b')) {
                    this.boletos_compra = (this.$route.query.b).split(',');
                }
                this.not = true;
            } else {

                return
            }

            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                id: this.id
            }
            await rifas.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.rifa_data = response
                    this.rifa_data.bv = [];
                    response.boletos_vendidos.forEach(element => {
                        this.rifa_data.bv.push(element.boleto)
                    });
                    //this.$router.push('/app/'); 
                } else {

                     emitter.emit("alerta_show",response.error);

                }
                emitter.emit("loading", false)
            });
        },
        async getButton() {
            /*
            
            'nombre'     => 'required|max:255',
                   'email'      => 'required|email',
                   'apellido'      => 'required|string',
                   'telefono'      => 'required|string',
                   'boletos'      => 'required|string',
                   'cantidad'      => 'required|string',
            
                   'rf'      => 'required|number',
            */
            let dat = {
                rf: this.id,
                nombre: this.nombre,
                apellido: this.apellido,
                correo: this.correo,
                telefono: this.telefono,
                boletos: this.boletos_compra.join(','),
                cantidad: this.boletos_compra.length
            };

            this.crear(dat)

        },

        async crear(dat) {
            emitter.emit("loading", true)

            await rifas.BUTTON(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    //this.limpiar();
                    //console.log(response)
                    //this.getData();
                    //window.location.assign(response)
                    window.location.href = response
                    //this.$router.push(response); 
                } else {

                    emitter.emit("alerta_show",response.error);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },

    }
}
</script>
