<template>



    <section class="absolute flex flex-wrap justify-center content-center w-full pt-8 pb-12 md:pb-24 bg-gray-900 overflow-hidden h-screen">
        <img class="absolute top-0 left-0 md:ml-20" src="aurora-assets/sign-up/shadow-top-green-light.png" alt="">
        <div class="relative container px-4 mx-auto">
          <div>
            <a class="inline-block mx-auto mb-8 rounded-full bg-green-700 p-3" href="#">
                <img @click="$router.push('/')" class="h-24" v-if="infoData.hasOwnProperty('logo')" :src="infoData.logo">
            </a>
            <div class="max-w-sm mx-auto">
              <div class="text-center">
                <h3 class="font-heading tracking-tight text-4xl font-bold text-white mb-4">Login</h3>
                <p class="text-gray-200 mb-8">Bienvenido ingresa a tu cuenta y crea tu proxima rifa.</p>
              </div>
              <form action="">
                <div class="mb-8">
                  <label class="text-start  block mb-2 text-sm font-medium text-white" for="">Usuario</label>
                  <input v-model="usuario" class="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg" type="email" placeholder="Enter your email">
                </div>
                <div class="mb-2">
                  <label class="text-start block mb-2 text-sm font-medium text-white" for="">Contraseña</label>
                  <div class="relative">
                    <input v-model="contrasena" class="relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg" type="password" placeholder="Enter password">
                    <button class="absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-green-600">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="text-right mb-8"><a class="inline-block text-sm font-semibold text-green-600 hover:text-green-500" href="#">Olvidaste tu contraseña?</a></div>
                <button type="button" @click="ingresar()" class="xs:flex-shrink-0 group relative flex w-full h-12 items-center justify-center px-4 p-px font-bold text-gray-900 bg-green-600 rounded-lg transition-all duration-300 focus:outline-none">
                  <div class="absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-900 animate-pulse group-hover:ring-0 transition duration-300"></div>
                  <span>Login</span>
                </button>
                <div class="my-4 flex items-center">
                  <div class="h-px w-full bg-gray-700"></div>
                  <span class="inline-block mx-4 text-xs font-medium text-gray-500">OR</span>
                  <div class="h-px w-full bg-gray-700"></div>
                </div>
               
                <p class="text-sm text-center">
                  <span class="mr-1 text-gray-500">No tienes una cuenta?</span>
                  <a class="inline-block text-green-600 hover:text-green-500 font-semibold" @click="router.push('/d/registro')">Registrate</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>

    

   


       
     
     

 

        


   
  


</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

//import TopRated from "@/components/TopRated.vue";
import {
    ref,
    reactive
} from "vue";
import userService from "@/Services/user";
import infosistem from "@/Services/infosistem";

import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import {
    useInfoStore
} from '@/stores/info'
import {
    useConfigStore
} from '@/stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins: [infosistem],
    props: {
        price: {
            type: Number,
            default: 0
        },

    },

    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const productPrice = reactive({
            name: "Product Name",
            price: props.price,
        });
        const infoData = ref({

        });
        const info = useInfoStore()
        const user = useUserStore()
        const config = useConfigStore()

        const active = reactive(false);
        const usuario = ref(8714393131);
        const contrasena = ref('10064656.');
        const visible= ref(false);
        // const add = () => productPrice.price++;

        const ingresar = async () => {
            console.log("ingresar");
            console.log("ingresar", usuario.value);
            let dat = {
                phone: usuario.value,
                password: contrasena.value
            };

            let data = await userService.LOGIN_USER(dat).then((response) => {
                console.log(response)
                if (response != null && !response.hasOwnProperty('error')) {
                    user.set(
                        response
                    );

                    if (response.user.role != 'cliente') {
                        router.push('/app/welcome');
                    } else {
                        router.push('/cl');
                    }
                    //if(response.user.role=='admin'){
                    //router.push('/app/welcome');

                    //this.$router.push('/app/'); 
                    //}
                } else {
                    alert("ocurrio un error");
                }
            });
        }
        const info_get = async () => {
            if (route.query.hasOwnProperty('cp')) {
                usuario.value = (route.query.cp).split('***')[1];
                contrasena.value = (route.query.cp).split('***')[0];
                visible.value=true;
            }

            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    info.set(
                        infov
                    );
                    infoData.value = (infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        }
        const registrar = async () => {
            console.log("registro", usuario.value);
            console.log(usuario.value, contrasena.value)
            if (usuario.value == '') {
                alert("Campo usuario es oblicatorio");
                return;
            }
            if (contrasena.value == '') {
                alert("Campo contrasena es oblicatorio");
                return;
            }
            let dat = {
                phone: usuario.value,
                password: contrasena.value,
                role: 'admin',
            };
            let data = await userService.REGISTRO_USER(dat).then((response) => {

                if (response != null) {
                    console.log(response);
                } else {
                    alert("ocurrio un error");
                }
            });
        }
        info_get()
        return {
            productPrice,
            registrar,
            ingresar,
            active,
            usuario,
            contrasena,
            config,
            user,
            router,
            route,
            infoData,
            visible

        };
    },
    data() {
        return {
           
            tipol: 'login'
            //usuario: '',
            //contrasena: '',
        }
    },
    mounted() {

        AOS.init({
            delay: 200,
            duration: 1200,
            once: false,
        })
    }
};
</script>

<style scoped>
.work-sans {
    font-family: 'Work Sans', sans-serif;
}

#menu-toggle:checked+#menu {
    display: block;
}

.hover\:grow {
    transition: all 0.3s;
    transform: scale(1);
}

.hover\:grow:hover {
    transform: scale(1.02);
}

.carousel-open:checked+.carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

#carousel-1:checked~.control-1,
#carousel-2:checked~.control-2,
#carousel-3:checked~.control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #000;
    /*Set to match the Tailwind colour you want the active one to be */
}
</style>
