//@ts-nocheck
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import VueFeather from 'vue-feather';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import VideoBg from 'vue-videobg'
import VuePictureSwipe from 'vue-picture-swipe';
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import VueApexCharts from "vue3-apexcharts";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import VueMobileDetection from "vue-mobile-detection";

//import VueImageZoomer from 'vue-image-zoomer'
//import 'vue-image-zoomer/dist/style.css';




const pinia = createPinia()


const app=createApp(App);
pinia.use(piniaPluginPersistedstate)
  app.use(MonthPicker)
  
  app.use(MonthPickerInput)
  app.use(VueApexCharts)
  app.use(router)
  app.use(VueMobileDetection)
  app.use(ElementPlus)
  

//app.use(VueImageZoomer)
  app.use(pinia);

  //app.use(VueFeather.name, VueFeather)
  app.component('VuePictureSwipe', VuePictureSwipe);
  app.component(VueFeather.name, VueFeather);
  app.component('VideoBg', VideoBg);
  app.mount("#app");
