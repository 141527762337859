<template>
  <div class="flex flex-col items-center mt-8">
    <div
    class="w-full flex flex-wrap justify-end z-30">
    <button @click="cerrar()" type="button" class="bg-red-700 rounded-full h-8 w-8 text-xl mb-2 flex flex-wrap justify-center content-center text-extrabold text-white"><span>X</span></button>
  </div>
    <div class="h-64 w-64 bg-white flex items-center justify-center text-6xl font-bold">
    
     
      <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
        <div v-if="isPickingWinner" :style="animatedNumberStyles">
         

         
          <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
          <svg v-if="corona"  class=" z-10" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
             viewBox="0 0 512 512" xml:space="preserve">
          <path style="fill:#FF709D;" d="M268.713,0l-12.716,10.062l12.718-10.06c8.785,11.101,19.717,24.917,19.717,51.051
            s-10.931,39.951-19.715,51.053c-7.383,9.331-12.717,16.072-12.717,30.927c0,14.854,5.334,21.594,12.716,30.925
            c8.784,11.101,19.716,24.917,19.716,51.05c0,26.135-10.931,39.949-19.715,51.051c-7.383,9.331-12.717,16.072-12.717,30.927
            c0,14.855,5.334,21.595,12.717,30.926c8.784,11.101,19.715,24.917,19.715,51.051c0,26.136-10.931,39.953-19.715,51.054
            c-7.383,9.333-12.717,16.075-12.717,30.933c0,14.859,5.333,21.6,12.714,30.929L243.28,512c-8.782-11.1-19.711-24.914-19.711-51.051
            c0-26.136,10.931-39.953,19.715-51.054C250.666,400.562,256,393.82,256,378.962c0-14.855-5.334-21.596-12.717-30.927
            c-8.784-11.101-19.715-24.917-19.715-51.05c0-26.135,10.931-39.95,19.715-51.051c7.383-9.331,12.717-16.072,12.717-30.927
            c0-14.855-5.334-21.595-12.717-30.926c-8.784-11.101-19.715-24.916-19.715-51.049c0-26.135,10.931-39.95,19.715-51.051
            C250.666,72.651,256,65.91,256,51.054c0-14.855-5.335-21.596-12.718-30.927L268.713,0z"/>
          <g>
            <path style="fill:#F63E31;" d="M109.299,0L96.583,10.062l12.718-10.06c8.785,11.101,19.716,24.917,19.716,51.051
              s-10.932,39.951-19.716,51.053c-7.382,9.331-12.716,16.072-12.716,30.927c0,14.854,5.334,21.594,12.716,30.925
              c8.784,11.101,19.716,24.917,19.716,51.05c0,26.135-10.931,39.949-19.715,51.051c-7.383,9.331-12.717,16.072-12.717,30.927
              c0,14.855,5.332,21.593,12.711,30.919l-25.435,20.124c-8.781-11.097-19.708-24.909-19.708-51.042
              c0-26.135,10.931-39.949,19.715-51.051c7.383-9.331,12.717-16.072,12.717-30.927c0-14.855-5.335-21.595-12.717-30.926
              c-8.784-11.101-19.715-24.916-19.715-51.049s10.931-39.95,19.715-51.051c7.383-9.331,12.717-16.072,12.717-30.928
              c0-14.855-5.335-21.596-12.718-30.927L109.299,0z"/>
            <path style="fill:#F63E31;" d="M428.127,0l-12.716,10.062l12.718-10.06c8.785,11.101,19.716,24.917,19.716,51.051
              s-10.932,39.951-19.716,51.053c-7.382,9.331-12.716,16.072-12.716,30.927c0,14.854,5.334,21.594,12.716,30.925
              c8.784,11.101,19.716,24.917,19.716,51.05c0,26.135-10.931,39.949-19.715,51.051c-7.383,9.331-12.717,16.072-12.717,30.927
              c0,14.855,5.332,21.593,12.711,30.919l-25.435,20.124c-8.781-11.097-19.708-24.909-19.708-51.042
              c0-26.135,10.931-39.949,19.715-51.051c7.383-9.331,12.717-16.072,12.717-30.927c0-14.855-5.335-21.595-12.717-30.926
              c-8.784-11.101-19.715-24.916-19.715-51.049s10.931-39.95,19.715-51.051c7.383-9.331,12.717-16.072,12.717-30.928
              c0-14.855-5.335-21.596-12.718-30.927L428.127,0z"/>
          </g>
          <circle style="fill:#FFD102;" cx="33.094" cy="215.006" r="16.879"/>
          <circle style="fill:#57BAB7;" cx="16.879" cy="460.946" r="16.879"/>
          <circle style="fill:#FFD102;" cx="176.297" cy="378.97" r="16.879"/>
          <circle style="fill:#57BAB7;" cx="160.082" cy="133.03" r="16.879"/>
          <circle style="fill:#FFD102;" cx="192.512" cy="51.054" r="16.879"/>
          <circle style="fill:#57BAB7;" cx="319.488" cy="297.241" r="16.879"/>
          <g>
            <circle style="fill:#FFD102;" cx="319.488" cy="460.946" r="16.879"/>
            <circle style="fill:#FFD102;" cx="351.918" cy="215.006" r="16.879"/>
          </g>
          <circle style="fill:#57BAB7;" cx="462.69" cy="460.946" r="16.879"/>
          <circle style="fill:#FFD102;" cx="495.121" cy="378.97" r="16.879"/>
          <circle style="fill:#57BAB7;" cx="478.906" cy="133.03" r="16.879"/>
          </svg>

         
         <p v-if="arrayd.length>0" class=" h-26 w-26 z-20 ">{{ currentNumber }}</p> 
         <p v-else class=" h-26 w-26 z-20 text-base ">No hay boletos vendidos</p> 
        </div >
        <p v-else></p>
       
      </transition>
    </div>
  </div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
export default {
  data() {
    return {
      isPickingWinner: false,
      currentWinner: null,
      corona: false,
      currentNumber: '',
    };
  },
  mounted() {
    this.pickWinner();
  },
  methods: {
    cerrar(){
      emitter.emit("cerrar_pickerwinner", true)
    },
    pickWinner() {
      this.isPickingWinner = true;
    
      const randomIndex = Math.floor(Math.random() * this.arrayd.length);
    if(this.arrayd.length==0){

    }else{
      this.currentWinner = this.arrayd[randomIndex];
console.log(this.currentWinner ,'---winner---')
const numberString = this.currentWinner.boleto;
let currentIndex = 0;

const intervalId = setInterval(() => {
  if (currentIndex < 100) {
    const randomDigit = Math.floor(Math.random() * 10);
    this.currentNumber = randomDigit;
    currentIndex++;
  } else {
    clearInterval(intervalId);
    this.currentNumber = numberString;
    emitter.emit("ganador", this.currentNumber)
    this.corona=true
  }
}, 30);
    }
      // Ajusta el valor si deseas cambiar la velocidad de la animación
    },
    beforeEnter(el) {
      el.style.transform = 'rotateX(180deg)';
      el.style.opacity = '0';
    },
    enter(el) {
      setTimeout(() => {
        el.style.transform = 'rotateX(0deg)';
        el.style.opacity = '1';
      }, 100);
    },
    afterEnter(el) {
      el.style.transform = '';
    },
  },
  computed: {
    animatedNumberStyles() {
      if (this.isPickingWinner) {
        return {
          transition: 'transform 0.2s, opacity 0.2s',
        };
      }
      return {};
    },
  },
  props: {
    arrayd: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.h-64 {
  height: 16rem;
}

.w-64 {
  width: 16rem;
}

.bg-white {
  background-color: #ffffff;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-2xl {
  font-size: 1.5rem;
}

.font-bold {
  font-weight: 700;
}
</style>
