<template>
  <div class="light w-full h-screen flex flex-wrap justify-center content-start bg-white">
  
     
      <transition name="bottom-modal" mode="out-in">
          <div v-if="showmodalloading" class="fixed inset-0 flex items-center justify-center z-40 px-4 py-6  sm:p-0 ">
            <!-- Fondo semitransparente -->
            <div class="fixed inset-0 transition-opacity" @click="showmodalloading=false">
              <div class="absolute inset-0 bg-black opacity-80"></div>
            </div>
      
            <!-- Contenedor del modal -->
            <div  class='!z-5 max-w-[378px] max-h-[368px] overflow-hidden relative flex h-full w-full flex-col rounded-xl  bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none'>
               
                <div class='h-full flex flex-wrap justif-center content-center w-full p-0 m-0'>
                
                <div class="jusfify-center content-center flex flex-wrap h-full w-full   bg-white rounded-xl">
                  <div  class="h-48 w-full">
                      <loadingInComponents></loadingInComponents>
                  </div>
                  <p class='w-full px-5 text-center text-base font-extrabold text-black md:!px-0  mt-4 '>
                      {{mensaje}}
                  </p>
      
              </div>
                </div>
              </div>
          </div>
        </transition>
  
      <transition name="bottom-modal" mode="out-in">
          <div v-if="showmodal" class="fixed inset-0 flex items-center justify-center z-40 px-4 py-6  sm:p-0 ">
            <!-- Fondo semitransparente -->
            <div class="fixed inset-0 transition-opacity" @click="showmodal=false">
              <div class="absolute inset-0 bg-black opacity-80"></div>
            </div>
      
            <!-- Contenedor del modal -->
            <div  :class="statusal?'bg-green-100':'bg-yellow-100'" class='!z-5 max-w-[378px] max-h-[368px] overflow-hidden relative flex h-full w-full flex-col rounded-[20px]  bg-clip-border p-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none'>
               
                <div class='h-full flex flex-wrap justif-center content-center w-full p-0 m-0'>
                
                <div class="jusfify-center content-center flex flex-wrap h-full w-full  ">
                  <div class='mb-auto flex flex-wrap items-center justify-center w-full'>
                      <div class="w-full flex flex-wrap justify-center content-center">
                          <div :class="statusal?'bg-green-500':'bg-yellow-500'"  class='mb-2 hover:bg-cginger-600 cursor-pointer mt-2 w-24 h-24 flex items-center justify-center rounded-full   p-[26px] text-5xl font-bold text-white dark:!bg-navy-700 dark:text-white'>
                              <i :class="statusal?' las la-check-double ':'las la-info '" class="d "></i>
                          </div>
                          <p class="text-black text-xs font-extrabold text-center w-full">{{statusal?'Notificacion':''}}</p>
                      </div>
                      <p class='w-full px-5 text-center text-base font-extrabold text-gray-800 md:!px-0 xl:!px-8 mt-4 w-full'>
                          {{mensaje}}
                      </p>
                      <div class="flex flex-wrap justify-between space-x-3">
                          
      
                      </div>
      
                  </div>
      
              </div>
                </div>
              </div>
          </div>
        </transition>
     
      <RouterView />
  </div>
  </template>
  
  <script >
  import loadingInComponents from '@/components/loadingInComponents.vue'
  import {
      ref,
      watchEffect
  } from "vue"
  import {
      RouterLink,
      RouterView,
      useRouter,
      useRoute
  } from 'vue-router'
  
  //import { MagicStick } from '@element-plus/icons'
  import {
      emitter
  } from "./mitt";
  import {
      useUserStore
  } from '@/stores/user'
  
  
  
  import router from "./router";
  // Import stylesheet
  //import 'vue-loading-overlay/dist/vue-loading.css';
  
  export default {
      components: {
       
          loadingInComponents
      },
      data() {
          const user = useUserStore()
          // const config = useConfigStore()
          const router = useRouter();
          const route = useRoute();
  
  
          return {
  
              user,
              router,
              route,
              //config,
              isLoading: false,
              fullPage: true,
              showmodal: false,
              mensaje: '',
              showmodalloading:false,
              statusal: false,
          }
  
      },
      created() {
  
          sessionStorage.clear();
          //localStorage.clear();
          console.log(document)
          //document.zoom = .8;
          var th = this;
          emitter.on('loading', (number) => {
                  console.log(number)
                  this.doAjax(number);
              }
  
          );
          emitter.on('alerta_show', (msg) => {
              console.log(msg)
              this.show_alert(msg);
  
          });
          emitter.on('loading_modal', (msg) => {
             
              this.loading_modal(msg.number,msg.msg);
  
          });
  
          
          emitter.on('success', (msg) => {
  
              this.success(msg);
  
          });
         
  
          /*this.user.$subscribe((mutation, state) => {
              // import { MutationType } from 'pinia'
              //mutation.type // 'direct' | 'patch object' | 'patch function'
              // same as cartStore.$id
              //mutation.storeId // 'cart'
              // only available with mutation.type === 'patch object'
              //mutation.payload // patch object passed to cartStore.$patch()
  
              // persist the whole state to the local storage whenever it changes
              localStorage.setItem('user', JSON.stringify(state))
          })*/
  
      },
      methods: {
          mounted() {
  
          },
          doAjax(statusy) {
              this.isLoading = status;
  
          },
          show_alert(msg) {
              this.mensaje = msg;
              this.statusal = false;
              this.showmodal = true;
          },
          loading_modal(number,msg) {
              this.mensaje = msg;
              if(number){
                  this.showmodalloading = true;
              }else{
                  this.showmodalloading = false;
              }
             
              
          },
          success(msg) {
              this.mensaje = msg;
              this.statusal = true;
              this.showmodal = true;
          },
      }
  }
  </script>
  
  <style>
  .bg-orange-one {
      background: #EE3C00;
  }
  
  .text-orange-one {
      color: #EE3C00;
  }
  .bg-crema{
      background:#f4e9df;
  }
  .bg-crema2{
      background:#edddce;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  div {
      
  
  }
  
  bottom-modal-enter-active,
  .bottom-modal-leave-active {
      transition: opacity 0.5s, transform 0.5s;
  }
  
  .bottom-modal-enter,
  .bottom-modal-leave-to
  
  /* .bottom-modal-leave-active in <2.1.8 */
      {
      opacity: 0;
      transform: translateY(100%);
  }
  
  /* Transición para la aparición desde abajo */
  .slide-up-enter-active,
  .slide-up-leave-active {
      transition: transform 0.6s ease;
  }
  
  .slide-up-enter,
  .slide-up-leave-to
  
  /* .slide-up-leave-active en <2.1.8 */
      {
      transform: translateY(100%);
  }
  
  
  
  .slide-up3-enter-active,
  .slide-up3-leave-active {
      transition: transform 4s ease;
  }
  
  .slide-up3-enter,
  .slide-up3-leave-to
  
  /* .slide-up-leave-active en <2.1.8 */
      {
      transform: translateY(100%);
  }
  
  
  /* Transición para el cierre hacia abajo */
  .slide-down-enter-active,
  .slide-down-leave-active {
      transition: transform 0.6s ease;
  }
  
  .slide-down-enter,
  .slide-down-leave-to
  
  /* .slide-down-leave-active en <2.1.8 */
      {
      transform: translateY(-100%);
  }
  *{
    font-family: 'Josefin Sans', sans-serif;
  }
  </style>
  