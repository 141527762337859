

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

 GET: async function( data){
      let url='/info/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_RIFAS_DOMAIN: async function( data){
      let url='/cuenta/'+data.domain
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_RIFAS_USERNAME: async function( data){
      let url='/rifas/username/'+data.username
      return initmixin.formatrespuesta('get', data,url,true)
    },
   


}