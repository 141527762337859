<template>
    <div>
      <button @click="openModal" class="flex items-center justify-center w-full h-12 px-4 text-center text-base text-white font-bold border border-gray-700 bg-green-900 bg-opacity-50 hover:bg-gray-700 shadow-sm hover:shadow-none rounded-lg transition duration-200">
       Contratar plan
      </button>

          
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-10">
        <div class="bg-white rounded-lg p-4 shadow-md w-96">
          <div class="mb-4">
            <div id="cardPaymentBrick_container"></div>
          </div>
          <button @click="closeModal" class="bg-red-500 text-white py-2 px-4 rounded">
            Cerrar Modal
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import pagos from "@/Services/pagos";

  export default {
    props: {
     
     plan:{
          type: Object,
       
     },
    
    },
    data() {
      return {
        showModal: false,
        mercadoPagoConfig: {
          publicKey: 'APP_USR-02e38b35-e703-486c-8a68-9154fc02e189',
          locale: 'es-MX',
          // Otras configuraciones de MercadoPago
        },
      };
    },
    methods: {
      openModal() {
        this.showModal = true;
        this.renderCardPaymentBrick();
      },

    async  CreaSuscripcion(obj) {
       console.log('data.----',obj)

       let ob=obj;
       ob.id=obj.mercadopago_plan_id;
            await pagos.CHECKOUT_PRO(ob).then(response => {

                if (response != null && response.hasOwnProperty('status')) {
                    emitter.emit("success", 'Suscrito correctamente ..');
                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    //this.imageURL = null;
                    emitter.emit("alerta_show", 'No se pudo actualizar el nombre de usaurio');

                }

            });
      },
      closeModal() {
        this.showModal = false;
        if (window.cardPaymentBrickController) {
          window.cardPaymentBrickController.destroy();
        }
      },
      async renderCardPaymentBrick() {
        const mp = new MercadoPago(this.mercadoPagoConfig.publicKey, {
          locale: this.mercadoPagoConfig.locale,
          // Otras configuraciones de MercadoPago
        });
        const bricksBuilder = mp.bricks();
        const settings = {
          initialization: {
            amount: this.plan.price,
            payer: {
              email: '',
            },
          },
          customization: {
            visual: {
              style: {
                customVariables: {
                  theme: 'flat',
                }
              }
            },
            paymentMethods: {
              maxInstallments: 12,
            }
          },
          callbacks: {
            onReady: () => {
              // Callback al estar listo
            },
            onSubmit: (cardFormData) => {

              return new Promise((resolve, reject) => {
                cardFormData.preapproval_plan_id=this.plan.mercadopago_plan_id;
                cardFormData.plan=this.plan;
                this.CreaSuscripcion(cardFormData)
                // Procesar la información del pago
                resolve();
              });
            },
            onError: (error) => {
              // Manejar errores
            },
          },
        };
  
        window.cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
      },
    },
  };
  </script>
  
  <style>
  /* Estilos del modal */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
  }
  </style>
  