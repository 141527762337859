

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 GET: async function( data){
  let url='/rifas/'
  return initmixin.formatrespuesta('get', data,url,true)
},
SHOW: async function( data){
  let url='/rifas/'+data.id
  return initmixin.formatrespuesta('get', data,url,true)
},
 GETACTIVAS: async function( data){
      let url='/rifas/activas/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    CREAR: async function( data) {
      let url='/rifas/'
      return initmixin.formatrespuesta('post', data,url,true)
    },

    GANADOR: async function( data) {
      let url='/rifas/'+data.id+'/ganador/'+data.numero
      return initmixin.formatrespuesta('post', data,url,true)
    },


    

    BUTTON: async function( data) {
      let url='/checkout/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
    EDITAR: async function( data) {
      let url='/rifas/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    ELIMINAR: async function( data) {
      let url='/rifas/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },
    
    MARCAR_PAGADO: async function( data) {
      let url='/rifas/venta/pagado/'+data.id
      return initmixin.formatrespuesta('post', data,url,true)
    },
    BORRAR_VENTA: async function( data) {
      let url='/rifas/venta/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },

}