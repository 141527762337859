<template>
 <!-- Navbar -->

   <div class="h-screen w-full  ">
    <div class="cho-container"></div>
   

   </div>

   
</template>
<script>


      const mp = new MercadoPago('APP_USR-12b8d90f-eed1-45e5-8053-3136ac0fc4ad', {
        locale: 'es-MX'
      });
    
      mp.checkout({
        preference: {
          id: 'MLM'
        },
        render: {
          container: '.cho-container',
          label: 'Pagar',
        }
      });
   
import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import rifas from "@/Services/rifas";
import generales from "@/functions/generales";

export default {
    components: {
        
    },
    mixins: [rifas,generales],
    data() {
        const user = useUserStore()

        return {
          
          
            user: user.getAll(),
          id:0,
            nombre: '',
            descripcion: '',
            precio: '',
            boletos: '',
            activo:true,
            data:[]

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {
        
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            console.log(e)

        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.descripcion = '';
            this.precio = '';
            this.boletos = '';


        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            this.precio = row.precio;
            this.boletos = row.boletos;


            this.showM = true;
            this.actionModal = null;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await rifas.GETACTIVAS(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {

          

            let dat = {
                id: this.id,
                nombre:this.nombre,
                descripcion:this.descripcion,
                precio:this.precio,
                boletos:this.boletos,
                activo:this.activo
            };

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await rifas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await rifas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                  this.limpiar();
                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
              
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await rifas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
