<template>
<div class="h-screen w-full  ">
    <div class="mx-auto w-full h-screen">
        <div class="h-full bg-gray-50 w-full">

            <div class="sticky top-0 w-full   h-12 px-5 py-2 bg-green-700 shadow-sm shadow-gray-300 z-30">
                <div v-if="infoset.hasOwnProperty('logo_favicon')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
                    <img class="w-10 h-10 rounded-full" :class="infoset.color_logo" :src="infoset.logo_favicon" :alt="infoset.nombre" />
                    
                </div>
                <span class="text-white">{{ dominio }}/{{numeroperfil}}</span>
            </div>

            <section v-if="numeroperfil==null" class="py-12 md:py-24 bg-gray-900 h-screen w-full ">
                <div class="container px-4 mx-auto">
                  <div class="max-w-6xl mx-auto ">
                    <div class="xs:max-w-sm md:max-w-sm lg:max-w-4xl mx-auto text-center mb-24">
                      <span class="inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full">POPCING AGENCY</span>
                      <h1 class="font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4">SISTEMA DE RIFAS , CREA UNA CUENTA EN MENOS DE UN MINUTO.</h1>
                      <p class="text-lg text-gray-400">En nuestro sistema podras crear rifas ilimitadas de manera sencilla y recibir los pagos inmediatamente por medio de mercado pago(Mercado libre)</p>
                    </div>
                    <div class="flex flex-wrap -mx-4 md:-mx-6 items-center  justify-center content-center">
                      <div class="w-full lg:w-1/3 px-4 md:px-6 mb-16 lg:mb-0">
                        <div class="max-w-xs mx-auto lg:mx-0 flex flex-wrap justify-center content-center">
                            <div class="flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-gray-800 rounded-full transition duration-200">1</div>
                            
                          <img class="block  mb-8 h-16 w-16" src="https://res.cloudinary.com/dgzea9m7q/image/upload/v1697849981/software/rifassistema/icons8-registro-96_1.png" alt="">
                          <div class="flex">
                           <div class="ml-3">
                              <h6 class="text-xl font-bold text-white mb-1">Crea una cuenta</h6>
                              <p class="text-lg text-gray-400">Solo se requiere tu numero de telefono y correo .</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-full lg:w-1/3 px-4 md:px-6 mb-16 lg:mb-0">
                        <div class="max-w-xs mx-auto lg:mx-0 flex flex-wrap justify-center content-center">
                            <div class="flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-gray-800 rounded-full transition duration-200">2</div>
                           
                          <img class="block  mb-8 h-16 w-16" src="https://res.cloudinary.com/dgzea9m7q/image/upload/v1697849981/software/rifassistema/icons8-rifa-100_1.png" alt="">
                          <div class="flex">
                             <div class="ml-3">
                              <h6 class="text-xl font-bold text-white mb-1">Crea una rifa</h6>
                              <p class="text-lg text-gray-400">Podras dar de alta un producto para rifa , subir fotos y poner el precio por boleto y la cantidad de boletos.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-full lg:w-1/3 px-4 md:px-6">
                        <div class="max-w-xs mx-auto lg:mx-0 flex flex-wrap justify-center content-center">
                            <div class="flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-gray-800 rounded-full transition duration-200">3</div>
                          
                          <img class="block h-16 w-16 mb-8" src="https://res.cloudinary.com/dgzea9m7q/image/upload/v1697849981/software/rifassistema/icons8-ganador-100_1.png" alt="">
                          <div class="flex">
                            <div class="ml-3">
                              <h6 class="text-xl font-bold text-white mb-1">Genera un ganador de manera aleatoria</h6>
                              <p class="text-lg text-gray-400">Solo es necesario que preciones un boton y de las personas que compraron tu boleto se generara automaticamente.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a @click="registroButton()" class="inline-block w-full sm:w-auto px-7 py-4 mb-40 text-center font-medium bg-green-500 hover:bg-green-600 text-white rounded transition duration-250" >Registrate</a>
                               
                    </div>
                  </div>
                </div>
              </section>







            <section class=" bg-gray-100 overflow-hidden">

                <div class="flex flex-wrap">
                    <div class="w-full lg:w-2/5 xl:w-1/2 relative order-last lg:order-first h-screen">
                        <img class="block w-full h-full object-cover" :src="infoset.banner_login" alt="">
                        <div class="h-26 bg-white"></div>
                        <div class="absolute top-0 mx-auto w-full sm:w-auto lg:w-3/5 xl:w-8/12 mt-24 sm:mr-6 xl:mr-24 px-4 h-5/6">
                            <div class="w-full mx-auto px-6 pt-5 pb-8 bg-white shadow rounded-xl h-auto overflow-hidden">
                                <div class="flex mb-8 items-center justify-between">
                                    <button class="hidden inline-flex items-center justify-center w-10 h-10 bg-indigo-500 hover:bg-indigo-600 text-white rounded" role="button">
                                        <svg width="10" height="10" viewbox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="3.60156" width="2.4" height="10.4" rx="1" fill="currentColor"></rect>
                                            <rect x="9.60156" y="4" width="2.4" height="9.6" rx="1" transform="rotate(90 9.60156 4)" fill="currentColor"></rect>
                                        </svg>
                                    </button>
                                   
                                   <!--<svg width="35" height="35" viewbox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="17" cy="18" r="16.5" stroke="black" stroke-opacity="0.12"></circle>
                                        <path d="M16.8645 1.27174C26.1782 1.27174 33.7285 8.82201 33.7285 18.1357" stroke="#5B2FE2" stroke-width="1.5" stroke-linecap="round"></path>
                                    </svg>--> 
                                </div>
                                <div class="text-black" v-if="data!=null&&data.hasOwnProperty('rifas')&&data.rifas!=null&&data.rifas.length>=1">
                                  <button class="inline-flex items-center justify-center w-10 h-10 border-2 border-green-500 hover:bg-green-600 text-green-600 font-extrabold rounded-full" role="button">
                                    {{ current+1 }}/{{data.rifas.length}}
                                 </button>
                                  
                                  <template v-for="(row,index) in data.rifas" v-bind:key="index">
                                    <div class="transition overflow-hidden w-full" :class="current==index?' h-auto':' h-0'">
                                        <img @click="$router.push('/detalle_boletos?r='+row.id)" class="block mx-auto mb-8 h-64" v-if="row.files.length>0" :src="row.files[0].url" alt="">
                                        <div class="flex items-center justify-center">
                                            <a @click="prev()"  class=" ease-in-out duration-300 inline-block rounded-full bg-green-300 w-10 h-10 flex flex-wrap justify-center content-center hover:bg-green-900 hover:text-white cursor-pointer" >
                                                <i class=" text-2xl las la-angle-left"></i>
                                            </a>
                                            <span @click="$router.push('/detalle_boletos?r='+row.id)" class="block mx-14 text-center w-8/12" >
                                                <h3 class="text-2xl">{{row.nombre}}</h3>
                                                <span class="text-xs text-gray-400">{{row.descripcion}}</span>
                                            </span>
                                            <span v-if="data.rifas.length>1" @click="next()" class=" ease-in-out duration-300 inline-block rounded-full bg-green-300 w-10 h-10 flex flex-wrap justify-center content-center hover:bg-green-900 hover:text-white cursor-pointer" >
                                                <i class="text-2xl las la-angle-right"></i>
                                            </span>
                                        </div>
                                    </div>

                                </template>
                                </div>
                                <div v-else>
                                  {{ data.rifas }}
                                  No hay rifas activas
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-3/5 xl:w-1/2 bg-gray-900 relative h-screen flex flex-wrap justify-center content-center">
                        <div class="px-4 pt-32 pb-10">
                            <div class="max-w-lg mx-auto">
                                <div class="mb-10">
                                    <!--<h1 class="font-heading text-4xl sm:text-3xl text-white mb-5">SISTEMA DE RIFAS , CREA UNA CUENTA EN MENOS DE UN MINUTO.</h1>
                                    <a class="inline-flex items-center text-lg text-white" href="#">
                                        <span class=" text-white hover:text-green-500">En nuestro sistema podras crear rifas ilimitadas de manera sencilla y recibir los pagos inmediatamente por medio de mercado pago(Mercado libre)</span>

                                    </a>-->
                                    <h1 class="font-heading text-4xl sm:text-3xl text-white mb-5">SISTEMA DE RIFAS GIVANO.</h1>
                                    <a class="inline-flex items-center text-lg text-white" href="#">
                                        <span class=" text-white hover:text-green-500">Da click en la foto para continuar</span>

                                    </a>

                                </div>
                               <!--<a @click="registroButton()" class="inline-block w-full sm:w-auto px-7 py-4 mb-40 text-center font-medium bg-green-500 hover:bg-green-600 text-white rounded transition duration-250" >Registrate</a>-->
                                <div>
                                    <!--<a class="inline-block mr-24 text-gray-500 hover:text-gray-400 text-sm" href="#">Let&rsquo;s talk</a>
                            <a class="inline-block text-gray-500 hover:text-gray-400 text-sm" href="#">Scroll for more</a>-->
                                </div>
                            </div>
                        </div>
                        <div class="hidden sm:block absolute bottom-0 right-0 h-26 w-26 bg-white">
                            <div class="absolute bottom-0 left-1/2 mb-8 transform translate-x-1/2">
                                <div class="h-26 w-px bg-white"></div>
                                <div class="h-18 w-px bg-gray-800"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

          

            <section class="py-12  bg-green-900">
                <div class="container px-4 mx-auto">
                  <div class="flex flex-wrap -mx-4 mb-10">
                    <div class="w-full lg:w-1/3 px-4 mb-16">
                      <div>
                        <img class="block h-8 mb-4" src="aurora-assets/logos/logo-aurora-sm-white.svg" alt="">
                        <span class="block text-start text-gray-400 mb-16">Sistema popcing</span>
                        <div class="flex items-center">
                         <!-- <a class="inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200" href="#">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z" fill="currentColor"></path>
                            </svg>
                          </a>
                          <a class="inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200" href="#">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="currentColor"></path>
                            </svg>
                          </a>-->
                          <a class="inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200" href="#">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z" fill="currentColor"></path>
                            </svg>
                          </a>
                          <!--<a class="inline-flex w-12 h-12 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200" href="#">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="currentColor"></path>
                            </svg>
                          </a>-->
                        </div>
                      </div>
                    </div>
                    <div class="w-full lg:w-2/3 px-4">
                      <!--<div class="flex flex-wrap -mx-4 -mb-8">
                        <div class="w-full xs:w-1/2 md:w-1/4 px-4 mb-8">
                          <h5 class="text-xl font-semibold text-white mb-6">Product</h5>
                          <ul>
                            <li class="mb-6"><a class="inline-block text-lg text-gray-400 hover:text-white" href="#">Features</a></li>
                            <li class="mb-6"><a class="inline-block text-lg text-gray-400 hover:text-white" href="#">Solutions</a></li>
                           
                          </ul>
                        </div>
                       
                       
                        
                      </div>-->
                    </div>
                  </div>
                  <div class="text-right">
                    <p class="text-gray-400">sistema creado por <a class="text-red-200" href="https://popcing.con">popcing</a>.</p>
                  </div>
                </div>
              </section>
        </div>
    </div>

</div>
</template>




  
  <script>
  import { ref, onMounted, computed, watch } from 'vue';
  import { useConfigStore } from '../stores/config';
  import rifas from "@/Services/rifas";
  import generales from "@/functions/generales";
  import infosistem from "@/Services/infosistem";
  import { useInfoStore } from '../stores/info';
  import { useUserStore } from '../stores/user';
  import { useRouter, useRoute } from 'vue-router';
  import {
    emitter
} from "@/mitt.js";

  export default {
    mixins:[generales],

    setup() {
      const router = useRouter();
      const route = useRoute();
      const user = useUserStore();
      const info = useInfoStore();
      const config = useConfigStore();
     const showM=ref(false);
     const actionModal=ref(false);
      const current = ref(0);
      const id = ref(0);
      const nombre = ref('');
      const descripcion = ref('');
      const precio = ref('');
      const dominio = ref('');
      const boletos = ref('');
      const activo = ref(true);
      const data = ref([]);
    const numeroperfil=ref(null);
      const salir = () => {
        user.limpiar([]);
        router.push('/');
      };
      const registroButton = () => {
        user.limpiar([]);
        router.push('/d/registro');
      };
  
      const obtenerDominio = () => {

        console.log(window.location.hostname,'----',route.params.numero)
        // Obtiene el dominio actual del sitio desde window.location
        dominio.value = window.location.hostname;


 //verifica si hay un username
 if (route.params.numero) {
            numeroperfil.value=route.params.numero;
            getDataUsername()
          }else{
            if(dominio.value=='cliente-rifas-popcing-60f9c0af3625.herokuapp.com'||dominio.value=='localhost'){
            numeroperfil.value=null
         

        }else{
          //getInforifasdomainDomain(dominio.value)
          numeroperfil.value=dominio.value;
        }
          }

        
      };
  
      const next = () => {
        if (current.value === (data.value.length - 1)) {
          current.value = 0;
        } else {
          current.value++;
        }
      };
  
      const prev = () => {
        if (current.value === 0) {
          current.value = data.value.length - 1;
        } else {
          current.value--;
        }
      };
  
      const get_info = async () => {
        console.log('data------');
        let data = await infosistem.GET().then((response) => {
          if (response != null && !response.hasOwnProperty('error')) {
            let infov = {};
            response.forEach(element => {
              infov[element.id] = element.valor;
            });
            info.set(infov);
          }
        });
      };
  
      const getDataUsername = async () => {
        showM.value = false;
        actionModal.value = null;
        emitter.emit("loading", true);
       
        let data2={
          username:route.params.numero
        }
        await infosistem.GET_RIFAS_USERNAME(data2).then(response => {
          if (response != null && !response.hasOwnProperty('error')) {
            data.value = response;
          } else {
            // emitter.emit("alerta_show",response.msg);
          }
          emitter.emit("loading", false);
        });
      
      };
  
      const getInfoNumber=(numero) =>{
      // Realiza la lógica para obtener información basada en el número
      console.log("Obteniendo información para el número: " + numero);
      numeroperfil.value=numero;
      // Puedes realizar una solicitud HTTP o cualquier otra lógica aquí
    }
      // Ejecutar las funciones al montar el componente
      onMounted(() => {
        get_info();
        obtenerDominio();
      

        if (route.params.numero) {
            console.log(route.params.numero,'--numero---')
      // Ejecuta getInfoNumber con el valor del parámetro
      //getInfoNumber(route.params.numero);
    }
      });
  
      return {
        infoset:info,
        numeroperfil,
        getInfoNumber,
        current,
        id,
        nombre,
        descripcion,
        precio,
        dominio,
        boletos,
        activo,
        data,
        salir,
        next,
        prev,
        router,
        registroButton
      };
    },
  };
  </script>
  