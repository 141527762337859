<template>
    <div class="relative">
      <input
       
        ref="textInput"
        type="text"
        :value="textToCopy"
        readonly
        @click="copyToClipboard"
        @focus="selectText"
        @blur="unselectText"
        class="p-2 border rounded focus:ring focus:ring-blue-400 cursor-pointer"
      />
     
      <div v-if="copied" class="absolute top-0 right-0 mt-2 mr-2 p-2 bg-green-500 text-white rounded-lg text-xs shadow animate-fade">
        Copiado al portapapeles
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  
  export default {
    props: {
      textToCopy: String,
    },
    setup(props) {
      const showInput = ref(false);
      const textInput = ref(null);
      const copied = ref(false);
  
      const copyToClipboard = () => {
        showInput.value = true;
        textInput.value.select();
        document.execCommand('copy');
        showInput.value = false;
        copied.value = true;
  
        setTimeout(() => {
          copied.value = false;
        }, 2000);
      };
  
      const selectText = () => {
        textInput.value.select();
      };
  
      const unselectText = () => {
        textInput.value.setSelectionRange(0, 0);
      };
  
      onMounted(() => {
        textInput.value = document.createElement('input');
        textInput.value.value = props.textToCopy;
        document.body.appendChild(textInput.value);
      });
  
      return {
        showInput,
        textInput,
        copied,
        copyToClipboard,
        selectText,
        unselectText,
      };
    },
  };
  </script>
  
  <style>
  .animate-fade-enter-active, .animate-fade-leave-active {
    transition: opacity 0.5s;
  }
  .animate-fade-enter, .animate-fade-leave-to {
    opacity: 0;
  }
  </style>
  