<template>
    <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
      <!-- Tabla de Dominios -->
      <Tabla :columns="headings" :rows="data" :filtros="['nombreDominio']" />
  
      <!-- Modal de Edición o Creación de Dominio -->
      <div v-if="showModal" class="fixed top-0 left-0 w-full flex flex-wrap justify-center content-center z-40 overflow-hidden h-screen">
        <div class=" absolute w-full h-screen bg-black bg-opacity-40">

        </div>
        <div class="max-w-4/6 h-5/6 bg-white z-20" >
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              {{ actionModal === null ? 'Crear Dominio' : 'Editar Dominio' }}
            </h3>
            <button @click="cerrarModal" class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
            </button>
          </div>
          <div class="relative p-6 flex-auto h-96 overflow-y-scroll flex flex-wrap content-start">
            <div class=" mt-2 justify-start flex flex-wrap w-full px-2">
              <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold" htmlFor="nombreDominio">
                Nombre de Dominio
              </span>
              <input v-model="nombreDominio" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre de Dominio">
            </div>
            <!-- ... (Otros campos del formulario si es necesario) -->
          </div>
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button @click="cerrarModal" class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              Cancelar
            </button>
            <button @click="guardarDominio" class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
              Guardar
            </button>
          </div>
        </div>
      </div>
  
      <!-- Confirmación de Eliminación -->
      <div v-if="actionModal !== null" class="confirmation-overlay">
        <div class="confirmation-container">
          <div class="text-base mb-4 font-bold text-slate-500">
            ¿Qué desea hacer?
          </div>
          <div class="flex-wrap flex justify-between w-full">
            <div class="w-3/6 px-2">
              <button @click="editarDominio(actionModal)" class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500">
                Editar
              </button>
            </div>
            <div class="w-3/6 px-2">
              <button @click="eliminarDominio(actionModal.id)" class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full border-2 border-red-500">
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { emitter } from '@/mitt.js';
  import { useUserStore } from '@/stores/user';
  import Dominios from '@/Services/dominios';
  import Tabla from "@/components/Tabla.vue";
  export default {
    components: {
        Tabla
    },
    setup() {
      const user = useUserStore();
  
      // Estado del componente
      const showModal = ref(false);
      const actionModal = ref(null);
      const data = ref([]);
      const nombreDominio = ref('');
  
      // Encabezados de la tabla
      const headings = [
        { key: 'id', name: '#', show: true },
        { key: 'nombre_de_dominio', name: 'Nombre de Dominio', show: true },
        // ... (Otros encabezados si es necesario)
        { key: 'editar', name: 'Editar', show: true },
        { key: 'eliminar', name: 'Eliminar', show: true },
      ];
  
      // Métodos
      const limpiar = () => {
        nombreDominio.value = '';
        // ... (Limpiar otros campos si es necesario)
      };
      const addRow=()=> {
        showModal.value = !showModal.value;
            limpiar()
        }
      const abrirModal = (row) => {
        actionModal.value = row;
        showModal.value = true;
        limpiar();
        if (row) {
          nombreDominio.value = row.nombreDominio;
          // ... (Establecer otros valores si es necesario)
        }
      };
  
      const cerrarModal = () => {
        showModal.value = false;
        actionModal.value = null;
        limpiar();
      };
  
      const guardarDominio = async () => {
        const dominio = { nombre_de_dominio: nombreDominio.value };
        // ... (Agregar otros campos al objeto dominio si es necesario)
  
        if (actionModal.value === null) {
          // Crear dominio
          await Dominios.CREAR(dominio);
        } else {
          // Editar dominio
          await Dominios.EDITAR(actionModal.value.id, dominio);
        }
  
        cerrarModal();
        cargarDatos();
      };
  
      const cargarDatos = async () => {
        emitter.emit('loading', true);
        data.value = await Dominios.GET(user.token);
        emitter.emit('loading', false);
      };
  
      const eliminarDominio = async (id) => {
        await Dominios.ELIMINAR(id);
        actionModal.value = null;
        cargarDatos();
      };
  
      const editarDominio = (row) => {
        abrirModal(row);
      };
  
      // Hook de montaje
      onMounted(cargarDatos);
  
      return {
        headings,
        showModal,
        actionModal,
        data,
        nombreDominio,
        abrirModal,
        cerrarModal,
        guardarDominio,
        eliminarDominio,
        editarDominio,
        addRow
      };
    },
  };
  </script>
  