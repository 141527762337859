<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-200 justify-center content-start ">

    <div class="w-full flex flex-wrap content-start justify-center h-full overflow-y-scroll">

        <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFileChange" />
        <div v-for="(row,index) in data" class=" px-8 pt-3 w-full" v-bind:key="index"  >
            <div class="h-full w-full rounded-xl shadow-xl bg-white overflow-hidden flex flex-wrap justify-start" >
                <div class="w-3/12 bg-gray-100 px-4 py-2 content-center flex flex-wrap justify-center">
                    <div class="w-full flex flex-wrap content-center justify-center">
                        <span class=" text-black px-3 py-1 mb-2 rounded-full text-xs z-30" :class="row.activo?'bg-green-500':'bg-red-500'">{{row.activo?'Activa':'Inactiva'}}</span>

                    </div>

                    <div v-if="row.files.length>0" class="lg:h-36 md:h-36 w-36 rounded-lg py-1 transition-all duration-400 flex flex-wrap justify-end hover:scale-100 relative" style="background-size:100% 100%;background-position:center;"  alt="blog">
                        <img :src="row.files[0].url" class="h-full w-full rounded-xl object-cover static">
                        <div class="h-8 w-8 text-xs rounded-full px-2 absolute top-2 right-2 flex flex-wrap justify-center content-center bg-blue-600 text-white font-extrabold"><span>{{row.files.length}}</span></div>
                        <div @click=" onPickFile(row.id)" class="h-8 w-8 absolute top-2 right-10 text-xs mx-1 rounded-full px-2 flex flex-wrap justify-center content-center bg-black text-white font-extrabold"><label>+

                            </label></div>
                    </div>
                    <div v-else class="lg:h-36 md:h-36 w-36 rounded-lg  transition-all duration-400 hover:scale-100
                      flex flex-wrap justify-end  px-2 py-2" 
                      style="background:url(https://res.cloudinary.com/dgzea9m7q/image/upload/v1627521854/popcing/6-06_iworfi.png);background-size:100% 100%;background-position:center;" alt="blog">
                        <div @click=" onPickFile(row.id)" class="h-8 w-8 text-xs rounded-full px-2 flex flex-wrap justify-center content-center bg-black text-white font-extrabold"><label>+

                            </label></div>
                    </div>
                </div>
                <div class=" w-6/12 bg-white py-5">
                    <h1 class="title-font text-lg px-8  text-left font-medium text-gray-600 mb-3"><strong class="text-green-600 font-extrabold">${{row.precio}}</strong>/{{row.nombre}}</h1>
                    <p class="leading-relaxed text-left px-4 mb-3 text-xs max-h-12">{{row.descripcion}}</p>

                    <div class="w-full flex flex-wrap justify-start py-3 px-3" >
                        <button  class="mx-1 my-1 px-4 py-2 rounded-full text-sm font-medium border-0 focus:outline-none focus:ring transition text-yellow-600  hover:text-yellow-800 hover:bg-yellow-100 active:bg-green-200 focus:ring-green-300" 
                        type="button"> <span class="py-1 px-3 bg-yellow-600 text-white rounded-full mx-2">{{row.boleto_ganador==null?'SIN GANADOR':row.boleto_ganador.venta.nombre+' / '+row.boleto_ganador.venta.telefono+' / '+row.boleto_ganador.boleto}}</span></button>


                        <button class="mx-1 my-1 px-4 py-2 rounded-full text-sm font-medium border-0 focus:outline-none focus:ring transition text-green-600  hover:text-green-800 hover:bg-green-100 active:bg-green-200 focus:ring-green-300" 
                        type="button"> <span class="py-1 px-3 bg-green-600 text-white rounded-full mx-2">${{formatPrice(Number(calcularVentas(row).toFixed(0)))}} Venta total</span></button>
                        <button @click="viewVentas=true;ventasd=row.ventas" type="button" class=" rounded-full mx-1 my-1 px-4 py-2  text-sm font-medium border-0 focus:outline-none focus:ring transition text-green-600 bg-green-50 hover:text-green-800 hover:bg-green-200 active:bg-green-200 focus:ring-green-300" 
                      > <span class="py-1 px-3 bg-black text-white rounded-full mx-2">{{row.boletos_vendidos.length}} / {{row.boletos}} Vendidos</span></button>
                       
                      <!--  <button  class="mx-1 my-1 px-4 py-2 rounded-md text-sm font-medium border-0 focus:outline-none focus:ring transition text-blue-600 bg-blue-50 hover:text-blue-800 hover:bg-blue-100 active:bg-blue-200 focus:ring-blue-300" type="button">Generar ganador</button>-->
                       <!--<button  class="mx-1 my-1 px-4 py-2 rounded-md text-sm font-medium border-0 focus:outline-none focus:ring transition text-yellow-600 bg-yellow-50 hover:text-yellow-800 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-yellow-300" type="button">Ver lista vendidos</button>-->

                       <!-- <button v-if="row.activo" @click="selCrearVenta(row)" class="mx-1 my-1 px-4 py-2 rounded-md text-sm font-medium border-0 focus:outline-none focus:ring transition text-gray-600 bg-gray-50 hover:text-gray-800 hover:bg-gray-100 active:bg-gray-200 focus:ring-gray-300" type="button"> Generar venta</button>-->
                       <!--<button @click="FinalizarRifa(row.id)" class="mx-1 my-1 px-4 py-2 rounded-md text-sm font-medium border-0 focus:outline-none focus:ring transition text-yellow-600 bg-yellow-50 hover:text-yellow-800 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-yellow-300" type="button">{{row.activo?'Finalizar Rifa'}}</button>-->

                    </div>

                </div>
                <div class="w-3/12 py-2 px-2 flex flex-wrap justify-end content-start">
                    <button @click="MGanadorVentas=true;rifasel=row;ventasd=row.boletos_vendidos" class="mx-1 my-1 h-12 w-12 flex flex-wrap  text-white   text-sm font-medium border-0 focus:outline-none focus:ring transition rounded-full  bg-yellow-500  hover:bg-yellow-600 active:bg-yellow-400 focus:ring-gray-300  justify-center content-center text-center  items-center" type="button"><i class="text-xl las la-star"></i></button>
                 

                    
                    <button @click="edit(row)" class="mx-1 my-1 h-12 w-12 flex flex-wrap  text-white   text-sm font-medium border-0 focus:outline-none focus:ring transition rounded-full  bg-blue-800  hover:bg-blue-600 active:bg-blue-400 focus:ring-gray-300  justify-center content-center text-center  items-center" type="button"> <i class="text-xl  las la-pen-nib"></i></button>
                 
                    <button v-if="row.activo" @click="selCrearVenta(row)" class="mx-1 my-1 h-12 w-12 flex flex-wrap  text-white   text-sm font-medium border-0 focus:outline-none focus:ring transition rounded-full  bg-green-800  hover:bg-green-600 active:bg-green-400 focus:ring-gray-300  justify-center content-center text-center  items-center" type="button"> <i class="text-xl  las la-shopping-bag"></i></button>
                 
                     
                </div>
            </div>
        </div>
    </div>

    <div v-if="MGanadorVentas" class="w-full flex flex-wrap justify-center content-center h-screen fixed top-0 left-0 z-30 bg-black bg-opacity-30">
        <WinnerPicker :arrayd="ventasd" />
    </div>
   

    <el-drawer v-model="showM" title="" @closed="getData()" :with-header="false" :size="'50%'" class="flex flex-wrap justify-center">
        <div class="w-full flex flex-wrap h-full">

            <div class="flex    h-full w-full">
                <!--content-->

                <div class=" relative flex flex-col w-full outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">

                        <p class="px-6 text-xl text-blue-600 text-center font-extrabold">{{ id==0?'Creación de rifa':'Editor de rifa' }}</p>

                    </div>
                    <!--body-->
                    <div class="relative p-6 pt-2   overflow-y-scroll flex flex-wrap w-full  content-start">
                       
 
                            
                            <div class="bg-white mt-6  rounded-lg p-6 w-full" v-if="id!=0">
                                <h3 class="text-gray-600 text-sm font-semibold mb-4">Imagenes</h3>
                                <ul class="flex items-center justify-start space-x-2 w-full">

                                    <!-- Story #1 -->
                                    <li v-for="(row,index) in files" v-bind:key="index" @click="eliminarFile(row.id,index)" class="flex flex-col items-center space-y-2">
                                        <!-- Ring -->
                                        <a class="block bg-red-200  rounded-full hover:shadow-lg  " style="background-position:center;backgorund-size:100% 100%;background-image:url(https://res.cloudinary.com/dgzea9m7q/image/upload/c_scale,w_50/v1666355527/popcing/icons8-close-480.png)" href="#">
                                            <img class="w-16 rounded-full h-16 hover:opacity-50" :src="row.url">
                                        </a>
                                        <!-- Username -->

                                    </li>
                                    <li @click=" onPickFile(id)" v-if="files.length<6" class="flex flex-col items-center space-y-2">
                                        <!-- Ring -->
                                        <a class="block bg-black  text-white rounded-full text-center hover:shadow-lg  h-16 w-16 flex flex-wrap content-center justify-center" style="background-position:center;backgorund-size:100% 100%;background-image:url(https://res.cloudinary.com/dgzea9m7q/image/upload/c_scale,w_50/v1666355527/popcing/icons8-close-480.png)" href="#">
                                            <label class="text-white text-3xl">+

                                            </label>
                                        </a>
                                        <!-- Username -->

                                    </li>

                                </ul>
                            </div>
                            <div class="w-full">
                                <label for="first_name" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nombre</label>
                                <input v-model="nombre" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre" required>
                            </div>
                            <div class="w-full">
                                <label for="last_name" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Descripcion</label>
                                <textarea v-model="descripcion" type="text" id="last_name" class="h-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Descripcion" required></textarea>
                            </div>
                            <div class="w-6/12">
                                <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"># Boletos</label>
                                <input type="text" :disabled="id!=0" :class="id!=0?'bg-red-50':'bg-gray-50'" v-model="boletos" id="company" class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="# Boletos" required>
                            </div>
                            <div class="w-6/12">
                                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Costo Boletos</label>
                                <input type="tel" :disabled="id!=0" :class="id!=0?'bg-red-50':'bg-gray-50'" v-model="precio" id="phone" class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Costo Boletos" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required>
                            </div>
                            <div class="flex items-start mb-6 w-full">
                                <div class="flex items-center h-5">
                                    <input id="remember" type="checkbox" v-model="activo" :checked="activo" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required>
                                </div>
                                <label for="remember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400">Rifa activa</label>
                            </div>

                        </div>

                    </div>
                    <!--footer-->

                </div>
    

        </div>

        <template #footer>
            <div style="flex: auto">

                <button @click="showM=false" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Cancelar</button>

                <button @click="guardar()" type="button" class="mx-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{{ id==0?'Crear':'Guardar' }}</button>

            </div>
        </template>
    </el-drawer>
    <el-drawer v-model="viewVentas" title="" @closed="getData()" :with-header="false" :size="'70%'" class="flex flex-wrap justify-center">
        <div class="w-full flex flex-wrap h-full justify-center">
           
            <div class="flex  flex-wrap  h-full w-full overflow-y-scroll content-start">
                <!--content-->

              <table class="w-full content-start">
                <thead>
                    <tr>
                        <th class="border border-black bg-gray-200 text-black sticky top-0">#Venta</th>
                        <th class="border border-black  bg-gray-200 text-black sticky top-0">#Boleto</th>
                        <th class="border border-black bg-gray-200 text-black sticky top-0">Nombre</th>
                        <th class="border border-black bg-gray-200 text-black sticky top-0">Correo</th>
                        <th class="border border-black bg-gray-200 text-black sticky top-0">Telefono</th>
                        <th class="border border-black bg-gray-200 text-black sticky top-0">card pago/codigo</th>
                        <th class="border border-black bg-gray-200 text-black sticky top-0">venta admin</th>
                        <th class="border border-black bg-gray-200 text-black sticky top-0">eliminar</th>
                    </tr>
                </thead>
                <tbody>

                    <template v-for="(row,index) in ventasd" v-bind:key="index">
                        <tr v-for="(r,i) in row.boletos_vendidos" v-bind:key="i">
                            <th>{{row.id}}</th>
                            <th>{{r.boleto}}</th>
                            <th>{{row.nombre}}</th>
                            <th>{{row.email}}</th>
                            <th><a :href="'tel:'+row.telefono">{{row.telefono}}</a></th>
                            <th>

                                <button class="text-green-500" v-if=" Number(row.venta_admin)==0&&r.payment_code==null" type="button" v-on:click="Pagar(row.id)">Pagar</button>
                                <strong v-else> {{
                                    Number(row.venta_admin)==1&&r.payment_code=='pagado'?'pagado':Number(row.venta_admin)==1?'separado':r.card_number+'/'+r.payment_code}}
                                    </strong>
                               



                                </th>
                            <th>
                                <button v-if=" Number(row.venta_admin)==1&&r.payment_code!='pagado'" type="button" v-on:click="Pagar(row.id)">Pagar</button>
                                <strong v-else> {{row.venta_admin}}</strong>
                               


                            </th>
                            <th><button type="button" v-on:click="borrar(row.id)">Eliminar</button></th>
                        </tr>
                    </template>
                    
                </tbody>
              </table>
            </div>
          

        </div>

        <template #footer>
            <div style="flex: auto">

                <button @click="viewVentas=false" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Cancelar</button>

               
            </div>
        </template>
    </el-drawer>
    <el-drawer v-model="viewVenta" title="" @closed="getData()" :with-header="false" :size="'90%'" class="flex flex-wrap justify-center">
        <div class="w-full flex flex-wrap h-full justify-center">
            <div class="flex  flex-wrap  h-full w-8/12 overflow-y-scroll">
                <!--content-->

                <div class="grid gap-6 mb-2 lg:grid-cols-2">

                    <div class="bg-white mt-2  rounded-lg  col-span-2">
                        <div class="flex flex-wrap   justify-between  w-full">
                            <!-- Story #1 -->
                            <template v-for="(row,index) in boletos" v-bind:key="index" @click="eliminarFile(row.id,index)">
                                <!-- Ring -->

                                <span :class="(boletos_sel.includes(index)?'bg-green-500 text-white font-extrabold':(boletos_vendidos.includes(index))?'bg-red-200 line-through':'bg-gray-200')"  @click="boletos_vendidos.includes(index)?null:addOrRemove(index)" class="w-16 my-1 text-center py-1 text-xs " >N{{index}}</span>
                                <!-- Username -->

                            </template>

                        </div>
                    </div>

                </div>
            </div>
            <div class="flex    h-full w-4/12">
                <!--content-->

                <div class=" relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">

                        <p class="px-6 text-xl text-blue-600 text-center font-extrabold">{{ 'Crear venta' }}</p>

                    </div>
                    <!--body-->
                    <div class="relative p-6 pt-2   overflow-y-scroll flex flex-wrap w-full">

                        <div class="grid gap-6 mb-6 lg:grid-cols-2">

                            <div class="">
                                <label for="first_name" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nombre</label>
                                <input v-model="nombre" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre" required>
                            </div>
                            <div class="">
                                <label for="first_name" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Apellido</label>
                                <input v-model="apellido" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Apellido" required>
                            </div>
                            <div class="">
                                <label for="first_name" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Correo</label>
                                <input v-model="correo" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Correo" required>
                            </div>
                            <div class="">
                                <label for="first_name" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Telefono</label>
                                <input v-model="telefono" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Telefono" required>
                            </div>

                        </div>

                    </div>
                    <!--footer-->

                </div>
            </div>

        </div>

        <template #footer>
            <div style="flex: auto">

                <button @click="viewVenta=false" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Cancelar</button>

                <button @click="CrearVenta()" type="button" class="mx-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Registrar Venta</button>

            </div>
        </template>
    </el-drawer>

    <button class="btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed z-20  float-right m-r-xxs rounded-full fixed  bottom-5 right-5 " @click="addRow()">
        <vue-feather :type="'plus-circle'" size="24" class="self-center" :stroke="'white'" />
    </button>
</section>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'

import generales from "@/functions/generales";
import rifas from "@/Services/rifas";

import files from "@/Services/files";
import ventas from "@/Services/ventas";
import WinnerPicker from '@/components/WinnerPicker.vue';
import {
    filter
} from "lodash";

export default {
    components: {
        WinnerPicker
    },
    mixins: [generales],
    data() {
        const user = useUserStore()

        return {

            user: user.getAll(),
            data: [],
            MGanadorVentas:false,
            data_file: null,
            imageUrl: '',
            showM: false,
            viewVenta: false,
            id: 0,
            el_sel: 0,
            rifasel:{},
            nombre: '',
            descripcion: '',
            precio: '',
            boletos: '',
            activo: true,
            files: [],
            telefono: '',
            apellido: '',
            correo: '',
            boletos_sel: [],
            ventasd:[],
            viewVentas:false

        }

    },
    mounted() {
        
        emitter.on('cerrar_pickerwinner', (data) => {

          this.cerrar_ganador();

        });
        emitter.on('ganador', (data) => {

                this.setGanador(data);

            });

    },
    created() {
        this.getData()
    },
    methods: {
        cerrar_ganador(){
            this.MGanadorVentas=false;
            this.rifasel={};
            this.ventasd=[]
        },
        async Pagar(id){
            await rifas.MARCAR_PAGADO({id:id}).then(response => {

if (response != null && !response.hasOwnProperty('error')) {

    this.getData();
    this.viewVentas = false;
    //this.viewVenta = false;
    //this.$router.push('/app/'); 
} else {
    //this.showM = false;
    emitter.emit("alerta_show",'Error al marcar como pagado');

}

emitter.emit("loading", false)
});
        },
        async borrar(id)
{
    await rifas.BORRAR_VENTA({id:id}).then(response => {

if (response != null && !response.hasOwnProperty('error')) {

    this.getData();
    this.viewVentas = false;
    //this.viewVenta = false;
    //this.$router.push('/app/'); 
} else {
    //this.showM = false;
    emitter.emit("alerta_show",'Error al seleccionar ganador');

}

emitter.emit("loading", false)
});
}   ,    async setGanador(ganador){
            await rifas.GANADOR({id:this.rifasel.id,numero:ganador}).then(response => {

        if (response != null && !response.hasOwnProperty('error')) {

            this.getData();
        
            //this.viewVenta = false;
            //this.$router.push('/app/'); 
        } else {
            //this.showM = false;
            emitter.emit("alerta_show",'Error al seleccionar ganador');

        }

emitter.emit("loading", false)
});
        },
        calcularVentas(row){
            let total=0;
            row.boletos_vendidos.forEach(element => {
                total=Number(total)+Number(element.precio)
            });
            return total;
        },
        selCrearVenta(row) {
            this.boletos_sel = [];
            this.boletos_vendidos=[];
            this.boletos = row.boletos;
            this.productos_carritos = row.productos_carritos;
            row.boletos_vendidos.forEach(element => {
                this.boletos_vendidos.push(element.boleto)
            });
            
            this.id = row.id;
            this.descripcion = row.descripcion;
            this.precio = row.precio;
            this.viewVenta = true;
        },
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
       
        addOrRemove(index){

            if(this.boletos_sel.includes(index)){
                this.boletos_sel= this.boletos_sel.filter(item => item.name === 'Matias')
            }else{
                this.boletos_sel.push(index)
            }

        },
        onPickFile(id) {
            this.el_sel = id
            this.$refs.fileInput.click()
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            this.subirarchivo();
            console.log(e)

        },
        async subirarchivo() {
            this.loader = true;
            this.$root.$emit("loading", true);

            emitter.emit("loading", true)
            var formData = new FormData();

            formData.append("doc", this.data_file, );
            let dat = {
                id: this.el_sel,
                data: formData
            }
            await files.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.data_file = null;
                    if(this.id!=0){
this.files.push(response)
                    }else{
                        this.getData();
                    }
                    
                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    this.data_file = null;
                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });

        },
        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.telefono = '';
            this.correo = '';
            this.apellido = '';
        
            this.descripcion = '';
            this.precio = '';
            this.boletos = '';
            this.activo = false;
            this.files = [];

        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            this.precio = row.precio;
            this.boletos = row.boletos;
            this.activo = row.activo;
            this.files = row.files;

            this.showM = true;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await rifas.GET().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },

      async  CrearVenta() {

            let dat = {
             id:this.id,
             precio:this.precio,
                nombre: this.nombre,
                apellido: this.apellido,
                telefono: this.telefono,
                correo: this.correo,
                boletos: this.boletos_sel
            };
            emitter.emit("loading", true)

            await ventas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.limpiar();
                    this.viewVenta = false;
                    //this.$router.push('/app/'); 
                } else {
                    this.showM = false;
                    // emitter.emit("alerta_show",response.msg);

                }

                emitter.emit("loading", false)
            });
            //this.viewVenta = false;

        },
        async guardar() {

            let dat = {
                id: this.id,
                nombre: this.nombre,
                descripcion: this.descripcion,
                precio: this.precio,
                boletos: this.boletos,
                activo: this.activo
            };

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await rifas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.limpiar();
                    this.showM = false;
                    //this.$router.push('/app/'); 
                } else {
                    this.showM = false;
                    // emitter.emit("alerta_show",response.msg);

                }

                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await rifas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.limpiar();
                    this.showM = false;
                    //this.$router.push('/app/'); 
                } else {
                    this.showM = false;
                    // emitter.emit("alerta_show",response.msg);

                }

                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await rifas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.limpiar();
                    this.showM = false;
                    //this.$router.push('/app/'); 
                } else {
                    this.showM = false;
                    // emitter.emit("alerta_show",response.msg);

                }

                emitter.emit("loading", false)
            });
        },

        async eliminarFile(id, pos) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await files.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    //this.getData();
                    this.files = this.files.splice(pos, 1)
                    //this.limpiar();

                    // this.showM = false;
                    //this.$router.push('/app/'); 
                } else {
                    // this.showM = false;
                    // emitter.emit("alerta_show",response.msg);

                }

                emitter.emit("loading", false)
            });
        },

    }
}
</script>
