<template>
<div class="h-screen w-full flex  justify-start content-start  ">





    

    <section class="py-12 md:py-24 bg-white w-full ">
        <div class="container px-4 mx-auto py-10" >





<section>
    <div class="pt-24 pb-32 lg:pb-24 bg-gray-900 hidden">
      <div class="container px-4 mx-auto ">
        <div class="max-w-md lg:max-w-6xl mx-auto mb-28">
          <div class="text-center mb-16">
            <h1 class="font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4">Selecciona el plan que desea contratar</h1>
            <p class="text-lg text-gray-400">Al realizar el pago obtendras los beneficios inmediatamente.</p>
          </div>
         <!-- <div class="flex justify-center">
            <div class="inline-flex items-center p-1 bg-gray-900 border border-gray-800 rounded-lg"><a class="inline-block px-4 mr-1 py-2.5 text-sm text-gray-400 bg-transparent border border-transparent rounded-lg" href="#">Monthly</a><a class="inline-block px-4 py-2.5 text-sm font-medium text-white bg-gray-800 rounded-lg" href="#">Annually</a></div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="pb-16 lg:pb-0 lg:h-112 bg-gray-800 hidden">
      <div class="container px-4 mx-auto">
        <div class="max-w-md lg:max-w-6xl mx-auto">
          <div class="flex flex-wrap -mx-4">

       
             
            <div v-for="(r,i) in planes_data" class="w-full lg:w-1/3 px-4 mb-48" v-bind:key="i">
              <div class="-mt-40 p-8 bg-gray-900 border border-gray-800 rounded-xl shadow-xl">
                <div class="flex mb-8 items-center">
                  <span class="mr-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 3.6V14.4C15 14.7314 14.7314 15 14.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M13.5 21H16.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M21 13.5V16.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M21 19.5V20.4C21 20.7314 20.7314 21 20.4 21H19.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M10.5 21H9.6C9.26863 21 9 20.7314 9 20.4V19.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M19.5 9H20.4C20.7314 9 21 9.26863 21 9.6V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M16.5 9H9.6C9.26863 9 9 9.26863 9 9.6V16.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                  <span class="text-base font-semibold text-white uppercase">{{r.name}}</span>
                </div>
                <div class="flex items-end mb-4">
                  <span class="mr-2 text-5xl font-semibold text-white tracking-tighter">${{r.price}}</span>
                 
                </div>
                <span class="w-full pb-2 text-lg text-gray-400">{{r.interval}}</span>
                <span class="block text-gray-400 mb-8">{{r.description}}</span>
                <div class="p-4 mb-8 border border-gray-700 rounded-xl">
                  <span class="block mb-4 text-sm font-semibold text-white">What’s included</span>
                  <ul>
                    <li class="flex items-center mb-4">
                      <div class="flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z" fill="white"></path>
                        </svg>
                      </div>
                      <span class="text-sm text-white">Complete documentation</span>
                    </li>
                    <li class="flex items-center mb-4">
                      <div class="flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z" fill="white"></path>
                        </svg>
                      </div>
                      <span class="text-sm text-white">Working materials in Figma</span>
                    </li>
                    <li class="flex items-center">
                      <div class="flex items-center justify-center w-5 h-5 mr-3 bg-gray-800 rounded-full">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z" fill="white"></path>
                        </svg>
                      </div>
                      <span class="text-sm text-white">100GB cloud storage</span>
                    </li>
                  </ul>
                </div>
                <ModalButtonMercado :plan="r"></ModalButtonMercado>
                   </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </section>




            <form class="mb-5 bg-gray-100 rounded-lg px-10 py-5">
                <p class="text-xs text-green-600">Ingresa un nombre de usuario, este servira para generar un link que podras compartir donde tus clientes encontraran tus rifas actuales</p>

                <div class="flex items-center py-2   ">
                   
                    <textarea v-model="name" id="chat" rows="1" class="block mb-0  p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  " placeholder="Username..."></textarea>
                    <button @click="GuardarUsername()" class="ml-1 border border-green-700 sm:flex-shrink-0 group relative w-full  sm:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-green-800 bg-green-300 rounded-lg transition-all duration-300 focus:outline-none" type="button">
                        <div class="absolute top-0 left-0 w-full h-full rounded-lg  animate-pulse group-hover:ring-0 transition duration-300"></div>
                        <span>GUARDAR</span>
                      </button>
                </div>

                <p v-if="urlactual!=null&&urlactual!=''">
                    <CampoCopiar :textToCopy="urlactual"></CampoCopiar>
                    </p>

            </form>
          <div class="py-16 px-8 md:px-12 xl:px-18 bg-green-50 border-2 border-green-700 rounded-xl">
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                <div>
                  <h3 class="text-4xl font-bold text-green-800 mb-4">Codigo Mercado pago</h3>
                  <p class="text-lg text-gray-600">Es necesario el token de mercado pago para poder recibir los pagos por los boletos de tu rifa, preciona el siguiente boton para saber como obtenerlo.

                    <wizardTutorialMercado></wizardTutorialMercado>
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="lg:max-w-md lg:ml-auto">
                  <div class="sm:flex mb-5 items-center">
                    <input v-model="access" class="py-3 px-4 h-12 w-full  sm:mb-0 font-extrabold  text-green-800 placeholder-gray-400 bg-green-100 border border-green-700 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg" type="text" placeholder="Acces token">
                    <button @click="GuardarToken()" class="ml-1 border border-green-700 sm:flex-shrink-0 group relative w-full  sm:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-green-800 bg-green-300 rounded-lg transition-all duration-300 focus:outline-none" type="button">
                      <div class="absolute top-0 left-0 w-full h-full rounded-lg  animate-pulse group-hover:ring-0 transition duration-300"></div>
                      <span>GUARDAR</span>
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


</div>
</template>

<script>
import {
    reactive,
    ref
} from "vue";

import perfil from "@/Services/perfil";
import pagos from "@/Services/pagos";
import wizardTutorialMercado from '@/components/wizardTutorialMercado'
import CampoCopiar from '@/components/CampoCopiar'
import ModalButtonMercado from '@/components/ModalButtonMercado'

import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import {
    useConfigStore
} from '@/stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    name: "Welcome",
    components: {
        wizardTutorialMercado,
        CampoCopiar,
        ModalButtonMercado
    },
    props: {
        title: {
            type: Number,
            default: 0
        },

    },
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const user = useUserStore()
        const config = useConfigStore()
        const email = ref(null)
        const telefono = ref(null)
        const name = ref(null)
        const access = ref(null)
        const secure = ref(null)
        const urlactual = ref(null)
        const planes_data = ref([])
        const plan = ref(null)
        const getPlanes = async () => {
            await perfil.GET_PLANES().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    planes_data.value=response;
                    
                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    //this.imageURL = null;
                    emitter.emit("alerta_show", 'No se pudo extaer la informacion de el link');

                }

            });

        }
        const getDataPerfil = async () => {
            await perfil.GET_DATA().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    access.value = response.tk_users.access;
                    secure.value = response.tk_users.secure;
                    name.value = response.name;
                    plan.value = response.suscripcion;
                    getDomainWithRoute()
                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    //this.imageURL = null;
                    emitter.emit("alerta_show", 'No se pudo extaer la informacion de el link');

                }

            });

        }
        const GuardarUsername= async () => {
            let dat={
                username:name.value
            }
            await perfil.ACTUALIZAR_USER(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    emitter.emit("success", 'NOMBRE DE USUARIO ACTUALIZADO ..');
                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    //this.imageURL = null;
                    emitter.emit("alerta_show", 'No se pudo actualizar el nombre de usaurio');

                }

            });

        }
        const getDomainWithRoute=() =>{
  const isSecure = window.location.protocol === "https:" ? "https://" : "http://";
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : "";
  if(name.value==null||name.value==''){

  }else{
    urlactual.value= `${isSecure}${domain}${port}/${name.value}`;
  }
 
}
        const GuardarToken = async () => {
            let dat={
                access:access.value
            }
            await perfil.ACTUALIZAR_TK(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    emitter.emit("success", 'TOKEN DE ACCESO ACTUALIZADO ..');
                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    //this.imageURL = null;
                    emitter.emit("alerta_show", 'No se pudo actualizar el token de accesso');

                }

            });

        }

        GuardarToken
        getDataPerfil()
        getPlanes();

        // expose to template and other options API hooks
        return {
            plan,
            getPlanes,
            GuardarUsername,
            GuardarToken,
            user: user.getAll(),
            getDataPerfil,
            config,
            router,
            route,
            email,
            telefono,
            name,
            access,
            secure,
            urlactual,
            planes_data
            

        }

    },

};
</script>

<style scoped>

</style>
