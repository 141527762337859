<template>
 
        <div class="absolute flex flex-wrap w-screen justify-center content-start h-screen overflow-y-scroll">
          <div class="w-full lg:w-1/2 px-5 bg-gray-900 h-screen">
            <div class="pt-8 pb-12 lg:pb-32 md:px-8">
              <div class="flex items-center justify-end mb-20">
                <span class="mr-2 text-sm text-white">ya tienes una cuenta?</span>
                <a class="group flex justify-center h-11 p-1 text-center text-sm text-white font-semibold rounded-lg" href="#">
                  <div class="inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800">
                    <div @click="loginRoute()" class="flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300">
                      <span>Ingresar</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="max-w-sm mx-auto">
                <h3 class="font-heading tracking-tight text-4xl font-bold text-white mb-4">Registrate</h3>
                <p class="text-gray-400 mb-8 text-start">Crea una cuenta gratuita y empieza compartir tu ptimera rifa.</p>
                <form action="">
                 
                  <div class="my-8 flex items-center">
                    <div class="h-px w-full bg-gray-700"></div>
            
                    <div class="h-px w-full bg-gray-700"></div>
                  </div>
                  <div class="mb-8">
                    <label class="text-start block mb-2 text-sm font-medium text-white" for="">Telefono</label>
                    <input  v-model="telefono" class="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg" type="number" placeholder="Telefono">
                  </div>
                  <div class="mb-8">
                    <label class="text-start block mb-2 text-sm font-medium text-white" for="">Correo</label>
                    <input v-model="correo" class="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg" type="email" placeholder="Correo">
                  </div>
                  <div class="mb-8">
                    <label class="text-start block mb-2 text-sm font-medium text-white" for="">Contraseña</label>
                    <input v-model="contrasena" class="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg" type="password" placeholder="Contraseña">
                  </div>
                  <button @click="CrearCuenta()" class="xs:flex-shrink-0 group relative w-full h-12 mb-8 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-green-600 rounded-lg transition-all duration-300 focus:outline-none" type="button">
                    <div class="absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-900 animate-pulse group-hover:ring-0 transition duration-300"></div>
                    <span>Crear cuenta</span>
                  </button>
                  <p class="text-sm">
                    <span class="mr-1 text-gray-500">Al crear cuenta aceptas los terminos y condiciones</span>
                    <a class="inline-block text-green-600 hover:text-green-500 font-semibold" href="#">Terminos y Politica de privacidad</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2 px-5 bg-gray-800  h-screen">
            <div class="flex h-full py-12 items-center justify-center">
              <div class="max-w-md sm:max-w-lg px-2">
                <div class="py-12 xs:px-10 border-t-2 border-b-2 border-gray-700">
                  <span class="block mb-5">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.072 0.351998L6.192 11.2H0.528L4.848 0.351998H9.072ZM17.568 0.351998L14.736 11.2H9.072L13.392 0.351998H17.568Z" fill="white"></path>
                    </svg>
                  </span>
                  <p class="text-2xl font-medium text-white mb-10">Una plataforma segura para tus proximas rifas , forma parte de nuestros socios!</p>
                  <div class="flex items-center justify-between">
                    <div>
                      <span class="block mb-1 text-sm font-semibold text-white"></span>
                      <span class="block text-xs text-gray-400">Popcing Agency.</span>
                    </div>
                    <img src="aurora-assets/logos/logo-aurora-white.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    



</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

//import TopRated from "@/components/TopRated.vue";
import {
    ref,
    reactive
} from "vue";
import userService from "@/Services/user";
import infosistem from "@/Services/infosistem";

import {
    emitter
} from "../mitt.js";
import {
    useUserStore
} from '../stores/user'
import {
    useInfoStore
} from '../stores/info'
import {
    useConfigStore
} from '../stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins: [infosistem],
    props: {
        price: {
            type: Number,
            default: 0
        },

    },

    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const productPrice = reactive({
            name: "Product Name",
            price: props.price,
        });
        const infoData = ref({

        });
        const info = useInfoStore()
        const user = useUserStore()
        const config = useConfigStore()


        const telefono = ref(8714393131);
        const contrasena = ref('');
        const correo = ref('');
        var id = ref(0);

      
       
     
       
        const email = ref('');
       
        var link = ref('');

        // const add = () => productPrice.price++;

        const info_get = async () => {

            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    info.set(
                        infov
                    );
                    infoData.value = (infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });

            if (route.query.hasOwnProperty('code')) {
                link.value = route.query.code;
            } else {
                link.value = ''
            }
            if (route.query.hasOwnProperty('u')) {
                id.value = route.query.u;
            } else {
                id.value = 0;
            }

        }
       
       
       
        const CrearCuenta = async () => {
            
            let dat = {
                phone: telefono.value,
                password: contrasena.value,
                email: correo.value,
            };
            let data = await userService.REGISTRO_USER(dat).then((response) => {

                if (response != null) {
                    user.set(
                        response
                    );
                    emitter.emit("success",'Usuario creado correctamente');
                    router.push('/app/welcome');
                } else {
                    alert("ocurrio un error");
                }
            });
        }

        const loginRoute= async()=>{
            router.push('/d/login')
        }

        info_get()
        return {
            telefono,
            CrearCuenta,
            loginRoute,
            productPrice,
            correo,
         
           
            link,
           
            email,
           
           
            config,
            user,
            router,
            route,
            infoData,
            contrasena
            
        };
    },
    data() {
        return {
            visible: false
            //usuario: '',
            //contrasena: '',
        }
    },
    mounted() {

    }
};
</script>

<style scoped>
</style>
