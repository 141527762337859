

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES


    CREAR: async function( data) {
      let url='/files/'+data.id
      return initmixin.formatrespuesta('post', data.data,url,true,true)
    },
    CREAR_VARIANTE: async function( data) {
      let url='/files/variante/'+data.id
      return initmixin.formatrespuesta('post', data.data,url,true,true)
    },
    
    
    ELIMINAR: async function( data) {
      let url='/files/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },
   


}