import { createRouter, createWebHistory } from "vue-router";

import Login from "../views/Login.vue";
import registro from "../views/Registro.vue";

import Root from "../views/Root.vue";
import HomePage from "../views/HomePage.vue";
import RootCliente from "../views/RootCliente.vue";

import Welcome from "../views/app/welcome.vue";
import usuarios from "../views/app/admin/usuarios.vue";
import Editrifa from "../views/app/admin/Editrifa.vue";
import nuevarifa from "../views/app/admin/nuevarifa.vue";
import rifas from "../views/app/admin/rifas.vue";

import ListaCompradoresPage from "../views/ListaCompradoresPage.vue";
import Final from "../views/Final.vue";
import checkout_success from "../views/checkout_success.vue";
import checkout_pending from "../views/checkout_pending.vue";
import checkout_failure from "../views/checkout_failure.vue";

import BoletosPage from "../views/BoletosPage.vue";
import DatosPage from "../views/DatosPage.vue";
import dominios from "../views/app/admin/Dominios.vue";

const routes = [
  {
    path: "/:numero?",
    name: "hp",
    component: HomePage
  },
  {
    path: "/boletos-vendidos",
    name: "lc",
    component: ListaCompradoresPage
  },
  {
    path: "/felicidades",
    name: "fl",
    component: Final
  },
  {
    path: "/checkout/success",
    name: "success",
    component: checkout_success
  },
  {
    path: "/checkout/pending",
    name: "pending",
    component: checkout_pending
  },
  {
    path: "/checkout/failure",
    name: "failure",
    component: checkout_failure
  },
  {
    path: "/detalle_boletos",
    name: "bp",
    component: BoletosPage
  },
  {
    path: "/informacion",
    name: "dp",
    component: DatosPage
  },

  {
    path: "/d/login",
    name: "login",
    component: Login
  },
  {
    path: "/d/registro",
    name: "registro",
    component: registro
  },
  

  
  {
    path: "/app",
    name: "app",
    component: Root,
    children: [
      
      
      {
        path: 'welcome',
        name: 'Welcome',
        component: Welcome
      },
      
      {
        path: 'rifas',
        name: 'rifas',
        component: rifas
      },
      {
        path: 'nueva/rifa',
        name: 'nueva_cotizacion',
        component: nuevarifa
      },
      {
        path: 'rifa',
        name: 'rifa',
        component: Editrifa
      },
      
     
    
      {
        path: 'usuarios',
        name: 'usuarios',
        component: usuarios
      },
      {
        path: 'dominios',
        name: 'dominios',
        component: dominios
      },
     
      

    ]
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
