<template>
    <div>
      <button
        @click="openModal"
        class=" underline rounded-full text-green-700 focus:outline-none focus:ring focus:ring-blue-400"
      >
        <i class="las la-facebook text-2xl mr-2"></i>
        Como obtener token mercado pago
      </button>
  
      <div v-if="showModal" @click.self="closeModal()" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 ">
        <div class="bg-white w-11/12 md:w-2/3 lg:w-1/2 rounded-lg  h-96 flex flex-wrap justify-center content-center px-20">
          <div class="mb-4">
            <h2 class="text-xl font-semibold mb-2 text-blue-700">{{ currentStep.title }}</h2>
            <p v-html="currentStep.content" class="h-48"></p>
          </div>
          <div class="flex justify-between w-full">
            <button
              @click="prevStep"
              :disabled="currentStepIndex === 0"
              class="bg-blue-600 text-white w-8 h-8 rounded-full hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-400"
            >
            <i class="las la-arrow-left"></i>
            </button>
            <button
              @click="nextStep"
              :disabled="currentStepIndex === wizardSteps.length - 1"
              class="bg-blue-600 text-white w-8 h-8 rounded-full hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-400"
            >
            <i class="las la-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  
  export default {
    setup() {
      const showModal = ref(false);
      const currentStepIndex = ref(0);
      const wizardSteps = [
        {
          title: "Paso 1: Crear una cuenta en Mercado Pago",
          content: `Si aún no tienes una cuenta en Mercado Pago, puedes crear una gratuitamente en
            <a href="https://www.mercadopago.com/" target="_blank" rel="noopener noreferrer"
            class="text-blue-600 hover:underline">el sitio web oficial de Mercado Pago</a>.`,
        },
        {
          title: "Paso 2: Iniciar sesión en tu cuenta",
          content: "Inicia sesión en tu cuenta de Mercado Pago utilizando las credenciales que proporcionaste durante el registro.",
        },
        {
          title: "Paso 3: Acceder al panel de desarrolladores",
          content: `Una vez que hayas iniciado sesión en tu cuenta, dirígete al
            <a href="https://www.mercadopago.com/developers/" target="_blank" rel="noopener noreferrer"
            class="text-blue-600 hover:underline">panel de desarrolladores de Mercado Pago</a>.`,
        },
        {
          title: "Paso 4: Crear una aplicación",
          content: "En el panel de desarrolladores, busca la opción para crear una nueva aplicación o proyecto. Deberás proporcionar detalles sobre tu aplicación, como su nombre, descripción y URL de redirección. Asegúrate de que el nombre de la aplicación sea 'sis_rifas_popcing'.",
        },
        {
          title: "Paso 5: Configurar OAuth 2.0",
          content: "Dentro de tu aplicación en el panel de desarrolladores, configura la autenticación basada en OAuth 2.0. Define las rutas de autorización y redirección para tu aplicación. Asegúrate de que los usuarios puedan autorizar el acceso de tu aplicación a su cuenta de Mercado Pago.",
        },
        {
          title: "Paso 6: Obtener el Access Token",
          content: "Una vez que tu aplicación esté configurada, los usuarios podrán autorizar el acceso. Al hacerlo, recibirás un Access Token que te permitirá interactuar con la API en nombre del usuario autorizado.",
        },
        {
          title: "Paso 7: Configurar Webhooks",
          content: `Dentro de tu aplicación 'sis_rifas_popcing', configura Webhooks en el panel de desarrolladores de Mercado Pago. Define la URL de Webhooks donde recibirás notificaciones en tiempo real sobre eventos de Mercado Pago, como pagos confirmados.`,
        },
       /* {
          title: "Paso 8: Utilizar el Access Token en tus solicitudes API",
          content: "Ahora que tienes el Access Token, puedes utilizarlo en tus solicitudes API a Mercado Pago en nombre del usuario autorizado. Asegúrate de seguir las prácticas de seguridad adecuadas al gestionar el Access Token.",
        },
        {
          title: "Paso 9: Procesar notificaciones de Webhooks",
          content: "Cuando Mercado Pago envíe notificaciones a tu URL de Webhooks, asegúrate de procesarlas y tomar las acciones necesarias en tu aplicación, como actualizar el estado de un pago.",
        },*/
      ];
  
      const currentStep = ref(wizardSteps[currentStepIndex.value]);
  
      const closeModal = () => {
        showModal.value = false;
        currentStep.value = wizardSteps[0];
        currentStepIndex.value = 0;
      };
      const openModal = () => {
        showModal.value = true;
        currentStep.value = wizardSteps[0];
        currentStepIndex.value = 0;
      };
  
      const prevStep = () => {
        if (currentStepIndex.value > 0) {
          currentStepIndex.value--;
          currentStep.value = wizardSteps[currentStepIndex.value];
        }
      };
  
      const nextStep = () => {
        if (currentStepIndex.value < wizardSteps.length - 1) {
          currentStepIndex.value++;
          currentStep.value = wizardSteps[currentStepIndex.value];
        }else{
            closeModal()
        }
      };
  
      return {
        closeModal,
        showModal,
        openModal,
        prevStep,
        nextStep,
        currentStep,
        currentStepIndex,
        wizardSteps,
      };
    },
  };
  </script>
  
  