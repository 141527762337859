<template>
 <!-- Navbar -->

   <div class="h-screen w-full  ">
    <div class="mx-auto max-w-[450px] h-screen">
        <div class="h-full bg-gray-50 w-full">
        
      
      
          <section class="p-5 h-full overflow-y-scroll flex flex-wrap content-start py-10 pb-20">
           <template v-for="(row,index) in data" v-bind:key="index">
            <div class="flex bg-white shadow mt-4  rounded-lg p-2 w-full flex-wrap justify-start content-center">
                <img v-if="row.files.length>0" :src="row.files[0].url" alt="imagenrifa" class="   object-cover  w-96 h-96 rounded-xl">
                <img v-else src="https://res.cloudinary.com/dgzea9m7q/image/upload/v1627521854/popcing/6-06_iworfi.png" alt="imagenrifa" class="w-96  object-cover h-96  rounded-xl">
                <div class="flex flex-col justify-center w-full px-2 py-1">
                    <div class="flex justify-between items-center ">
                        <div class="flex flex-col">
                            <h2 class="text-xl font-medium">{{row.nombre}}</h2>
                        </div>
                       
                    </div>
                    <div class="flex pt-2  text-sm text-gray-400">
                        <div class="flex items-center mr-auto">
                            <i class="las la-money-bill-wave-alt text-green-500 text-xl"></i>
                            <p class="font-extrabold px-2"> ${{formatPrice(Number(row.precio).toFixed(0))}}<span class="text-gray-400 text-sm font-normal"> MX</span></p>
                        </div>
                        <div class="flex items-center font-medium text-gray-900 ">
                           
                            
                        </div>
                    </div>
                </div>
            </div>
           </template>
          </section>
      
          <div class="sticky bottom-0 w-full  bg-white h-12 px-5 py-2 bg-yellow-500 shadow-sm shadow-gray-300">
            <div class="w-full text-center flex flex-wrap content-center justify-center h-full">
<p class="text-black font-extrabold text-xs">Desarrollado por @popcing</p>
            </div>
            <!--<nav class="flex justify-around text-gray-900">
              <a href="#" class="rounded-full bg-slate-100 p-3 text-indigo-600">
                <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
              </a>
              <a href="#" class="py-3">
                <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
              </a>
              <a href="#" class="py-3">
                <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
              </a>
              <a href="#" class="py-3">
                <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                </svg>
              </a>
            </nav>-->
          </div>
        </div>
      </div>

   </div>

   
</template>
<script >
import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import rifas from "@/Services/rifas";
import generales from "@/functions/generales";

export default {
    components: {
        
    },
    mixins: [rifas,generales],
    data() {
        const user = useUserStore()

        return {
          
          
            user: user.getAll(),
          id:0,
            nombre: '',
            descripcion: '',
            precio: '',
            boletos: '',
            activo:true,
            data:[]

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {
        
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            console.log(e)

        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.descripcion = '';
            this.precio = '';
            this.boletos = '';


        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            this.precio = row.precio;
            this.boletos = row.boletos;


            this.showM = true;
            this.actionModal = null;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await rifas.GETACTIVAS(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {

          

            let dat = {
                id: this.id,
                nombre:this.nombre,
                descripcion:this.descripcion,
                precio:this.precio,
                boletos:this.boletos,
                activo:this.activo
            };

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await rifas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await rifas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                  this.limpiar();
                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
              
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await rifas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
