<template>
<!-- Navbar -->

<div class="h-screen w-full  ">
    <div class="w-full h-screen overflow-hidden">
        <div class="sticky top-0 w-full  bg-green-700 h-12 px-5 py-2 shadow-sm shadow-gray-300">
            <div v-if="infd.hasOwnProperty('logo_favicon')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
                <img class="w-10 h-10 rounded-full" :class="infd.color_logo" :src="infd.logo_favicon" :alt="infd.nombre" />
            </div>

        </div>
        <div class="h-full bg-gray-50 w-full  ">

            <section v-if="!not" class=" h-full  flex flex-wrap content-start overflow-y-scroll pt-10 pb-48">

                <div v-if="rifa_data!=null" class="px-10  w-full sm:w-full lg:w-6/12 xl:w-6/12 h-auto xs:h-auto sm:h-auto  md:h-full lg:h-full xl:h-full   md:w-6/12 flex  px-4 sm:px-4 md:px-16 lg:px-16 xl:px-16 flex-wrap justify-center content-start">
                    <div class="w-full flex flex-wrap   content-start h-full ">
                        <span class="hidden pl-3 cursor-pointer font-extrabold text-base mb-2" @click="$router.push('/')"><i class="las la-arrow-left"></i> Regresar a página de inicio</span>
                        <div class="w-full flex flex-wrap h-96 sm:h-96 md:h-5/6 xl:h-5/6 lg:h-5/6 pl-2 content-center justify-start" v-if="rifa_data.files.length>0">

                            <img class="w-96 bg-gray-200 h-96 object-cover rounded overflow-hidden
                             hover:shadow-md hover:drop-shadow-md transition-all" :src="selimg==null?rifa_data.files[0].url:selimg.url">
                        </div>
                        <div class="w-full flex flex-wrap justify-start h-1/6 my-10 xs:my-10 sm:my-10 md:my-0 lg:my-0 xl:my-0 content-start ">
                            <template v-for="(row,index) in rifa_data.files" v-bind:key="index">
                                <div  class="w-16 xs:w-16 sm:w-16 md:w-24 lg:w-24 xl:w-24  h-16 xs:h-16 sm:h-16 md:h-24 lg:h-24 xl:h-24  px-2   py-2" @click="CambiarPosicion(index)">
                                    <img class="w-full h-full object-cover rounded overflow-hidden" :src="row.url">
                                </div>
                            </template>

                        </div>
                    </div>

                </div>
               
                <div class="sm:w-full lg:w-6/12 xl:w-6/12   md:w-6/12  h-auto xs:h-auto sm:h-auto md:h-full lg:h-full xl:h-full flex flex-wrap justify-start content-start overflow-visible xs:overflow-visible sm:overflow-visible md:overflow-hidden pb-20 xs:pb-20 sm:pb-20 md:pb-10 lg:pg-10 xl:pb-10 ">
                    <div v-if="rifa_data!=null" class="px-10">
                        <div class="text-2xl font-extrabold w-full text-start ">{{rifa_data.nombre}}</div>
                        <span class="text-xl text-gray-500 font-medium text-left" v-html="rifa_data.descripcion"></span>
                        <div class="text-2xl font-extrabold w-full text-start ">$ {{Number(rifa_data.precio).toFixed(2)}}</div>
                    </div>
                    <div v-if="rifa_data!=null" class="w-full flex flex-wrap h-full overflow-visible xs:overflow-visible sm:overflow-visible md:overflow-hidden">

                        <div class="w-full flex flex-wrap  h-full ">
                           
                            <div class="flex flex-wrap   justify-between content-start overflow-visible xs:overflow-visible
                             sm:overflow-visible md:overflow-y-scroll  lg:overflow-y-scroll xl:overflow-y-scroll  pb-40 pt-10  w-full px-4 sm:px-4 md:px-16 lg:px-16 xl:px-16 h-full">

                                <template v-for="(row,index) in rifa_data.boletos+1" v-bind:key="index">
                                    <!-- Ring -->
<div v-if="(index>0)" class="w-3/12  text-center  px-1 py-1 flex flex-wrap">


    
    <div  :class="(boletos_sel.includes(index)?'bg-white text-black border-2 border-black font-extrabold':(rifa_data.bv.includes(index))?'bg-gray-100 text-gray-500 linn border':'bg-white font-extrabold border')"  @click="rifa_data.bv.includes(index)?null:addOrRemove(index)" class="w-full  text-center  py-1 text-xl  cursor-pointer ">N{{index}}</div>
                                  
</div>
                                     <!-- Username -->

                                </template>

                            </div>

                            <!--<ul class="flex justify-center bg-white sticky bottom-0 items-center my-4 px-16 w-full">
                                <template v-for="(tab,index) in Math.ceil(rifa_data.boletos/paginate)" :key="index">
                                    <li class="cursor-pointer py-2 font-extrabold px-4 text-gray-500 border-b-8"
                                        :class="activeTab===tab ? 'text-black-500 border-black' : ''" @click="activeTab = tab"
                                        > {{((tab-1)*paginate)+1}} - {{(tab*paginate)>rifa_data.boletos?rifa_data.boletos:(tab*paginate)}}</li>
                                </template>
                            </ul>-->

                        </div>
                        <div class=" mt-2  rounded-lg  col-span-2">
                            <div class="flex flex-wrap   justify-between  w-full">
                                <!-- Story #1 -->

                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <section v-if="not" class="p-5 h-full overflow-y-scroll flex flex-wrap content-center py-10 pb-20">
                <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div class="max-w-md text-center content-center flex flex-wrap justify-center">
                        <!--<h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
                            <span class="sr-only">Error</span>404
                        </h2>-->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-40 h-40 dark:text-gray-600">
                            <path fill="currentColor" d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"></path>
                            <rect width="176" height="32" x="168" y="320" fill="currentColor"></rect>
                            <polygon fill="currentColor" points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"></polygon>
                            <polygon fill="currentColor" points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"></polygon>
                        </svg>
                        <p class="text-2xl font-semibold md:text-3xl">La rifa ya no se encuentra disponible.</p>
                        <p class="mt-4 mb-8 dark:text-gray-400">favor de regresar a la pagina de inicio para encontrar nuestras ultimas rifas.</p>
                        <a rel="noopener noreferrer" href="/" class="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Regresar a inicio</a>
                    </div>
                </div>
            </section>

            <button class="btn font-bold bg-green-800 border-2 border-white text-white text-3xl w-24 h-24 justify-center content-center  flex flex-wrap fixed bottom-5 z-20 right-10 float-right m-r-xxs rounded-full" @click="drawer=true">
                <i class="las la-shopping-bag text-5xl"></i>
                <span v-if="boletos_sel.length>0" class="bg-black -mt-2 -mr-8 absolute rounded-full text-xs h-6 w-6 text-center flex flex-wrap justify-center content-center">
                    <label>{{boletos_sel.length}}</label></span>
            </button>
            <el-drawer v-model="drawer" title="Boletos selecionados" :with-header="false" :size="$isMobile()?'100%':'40%'">

        
                <span>Boletos agregados a carrito</span>

                <div class="w-full px-2 py-2 flex flex-wrap overflow-y-scroll">

                </div>
                <template v-for="(r,i) in boletos_sel" v-bind:key="i">
                    <div @click="addOrRemove(r)" class="w-full px-4 border border-gray-200 font-extrabold my-2 h-12 content-center items-center rounded bg-gray-200 flex flex-wrap justify-between ">
                        <span>Boleto #{{r}} </span> <span class="text-red-500 font-extrabold rounded-full bg-red-100 w-10 h-10 flex flex-wrap justify-center content-center border border-red-500"><i class="las la-trash-alt"></i></span>
                    </div>
                </template>

                <div v-if="rifa_data!=null"  class="text-left w-full my-10 text-black text-base" >
                    <a :href="'https://wa.me/+52'+rifa_data.telefono" class="text-black" target="_blank">¿Problemas para comprar?<br> Envía un mensaje por WhatsApp <strong class="text-green-600 underline cursor-pointer" >Enviar mensaje</strong></a>

                </div>
                <template #footer>
                    <div class="dialog-footer">
                        <button type="button" class="border-2 border-black text-black font-extrabold py-3 px-4" @click="drawer=false">Cerrar</button>
                        <button type="button" @click="boletos_sel.length>0?continuar():null" class="bg-black border-2 border-black ml-4 text-white font-extrabold py-3 px-4">Comprar ahora</button>
        
                    </div>
                </template>
                
            </el-drawer>

        </div>
        <div class="sticky bottom-0 w-full   h-12 px-5 py-2 bg-green-500 shadow-sm shadow-gray-300">
            <div class="w-full text-center flex flex-wrap content-center justify-center h-full">
                <p class="text-white font-extrabold text-xs">Desarrollado por @popcing</p>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '../stores/config'

import rifas from "@/Services/rifas";
import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import {
    useInfoStore
} from '../stores/info'

import {
    useUserStore
} from '../stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
import { join } from "path";

export default {
    components: {

    },
    mixins: [rifas, generales],
    setup() {
        const router = useRouter()
        const route = useRoute()

        const info = useInfoStore()

        const config = useConfigStore();

        const salir = () => {
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,

            config,
            info: info.getAll(),
            infoset: info,

            salir

        }

    },
    data() {
        const user = useUserStore()

        return {
            drawer: false,
            activeTab: 1,
            user: user.getAll(),
            id: 0,
            nombre: '',
            paginate: 200,
            descripcion: '',
            precio: '',
            boletos: '',
            boletos_sel: [],
            boletos_vendidos: [],
            activo: true,
            data: [],
            rifa_data: null,
            not: false,
            selimg:null,
            infd:{}

        }

    },
    mounted() {
this.get_info()
    },
    created() {
        this.getData()
    },
    methods: {
        continuar(){
        
let join_boletos=this.boletos_sel.join(',');
            this.$router.push('/informacion?b='+join_boletos+'&r='+this.id)
        },
        CambiarPosicion(index) {
            console.log(index)
             this.selimg=this.rifa_data.files[index]
            /*let old1 = JSON.parse(JSON.stringify(this.rifa_data.files[0]));
            this.rifa_data.files[0] = JSON.parse(JSON.stringify(this.rifa_data.files[index]));
            this.rifa_data.files[index] = old1;*/

        },
        addOrRemove(index) {
            console.log(this.boletos_sel)
            if (this.boletos_sel.includes(index)) {
                this.boletos_sel = this.boletos_sel.filter(item => item != index)
            } else {
                this.boletos_sel.push(index)
            }

        },
        async get_info() {
            console.log('data------')
            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};

                    console.log(response,'---pp---')
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    this.infoset.set(
                        infov
                    );
                    this.infd=infov
                  
                    //infoData.value=(infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        },
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            console.log(e)

        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.descripcion = '';
            this.precio = '';
            this.boletos = '';

        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            this.precio = row.precio;
            this.boletos = row.boletos;

            this.showM = true;
            this.actionModal = null;
        },
        async getData() {

            if (this.$route.query.hasOwnProperty('r')) {
                this.id = this.$route.query.r;
            } else {
                this.not = true;
                return
            }

            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                id: this.id
            }
            await rifas.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.rifa_data = response
                    this.rifa_data.bv = [];
                    response.boletos_vendidos.forEach(element => {
                        this.rifa_data.bv.push(element.boleto)
                    });
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {

            let dat = {
                id: this.id,
                nombre: this.nombre,
                descripcion: this.descripcion,
                precio: this.precio,
                boletos: this.boletos,
                activo: this.activo
            };

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await rifas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await rifas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.limpiar();
                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await rifas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
<style>
.bg-p{
    background:#306FDB   
}

.line{
    content: "";
    box-shadow: 0 0 0 2px rgb(0,0,0);
    border-radius: inherit;
    opacity: 0;
    will-change: transform;
    transition: opacity .2s ease-in-out,transform .2s ease-in-out;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(.95);
}
.linn{
    background-image: linear-gradient(to bottom right,#0000 50%,currentColor 50% calc(50% + 2px),#0000 calc(50% + 2px));
}
</style>