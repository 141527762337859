<template>


    <div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal w-full flex-wrap justify-between content-center h-screen">

    <section class="relative py-20 sm:py-32 lg:py-64 overflow-hidden">
        <img class="absolute top-0 left-0" src="saturn-assets/images/http-codes/blue-light-left.png" alt="">
        <img class="absolute bottom-0 right-0" src="saturn-assets/images/http-codes/orange-light-right.png" alt="">
        <div class="relative container px-4 mx-auto">
          <div class="sm:flex items-start justify-center max-w-5xl mx-auto">
           
            <div class="max-w-xs md:max-w-sm lg:max-w-2xl">
              <h1 class="font-heading text-4xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-6">
                <span></span>
                <span class="font-serif italic">Pago exitoso</span>
              </h1>
              <p class="max-w-xs md:max-w-sm lg:max-w-2xl text-xl font-semibold text-gray-500 mb-14">Favor de revisar su whatsapp para ver sus boletos comprados</p>
              <a @click="$router.push('/')" class="relative group inline-block py-3 px-5 text-center text-sm font-semibold text-orange-50 bg-green-600 rounded-full overflow-hidden" href="#">
                <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span class="relative hover:text-green-500 text-white">Ir a pagina principal</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
    

</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

//import TopRated from "@/components/TopRated.vue";
import {
    ref,
    reactive
} from "vue";
import userService from "@/Services/user";
import infosistem from "@/Services/infosistem";

import {
    emitter
} from "../mitt.js";
import {
    useUserStore
} from '../stores/user'
import {
    useInfoStore
} from '../stores/info'
import {
    useConfigStore
} from '../stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins: [infosistem],
    props: {
        price: {
            type: Number,
            default: 0
        },

    },

    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const productPrice = reactive({
            name: "Product Name",
            price: props.price,
        });
        const infoData = ref({

        });
        const info = useInfoStore()
        const user = useUserStore()
        const config = useConfigStore()

        const active = reactive(false);
        const usuario = ref(8714393131);
        const contrasena = ref('10064656.');
        const visible= ref(false);
        // const add = () => productPrice.price++;

        const ingresar = async () => {
            console.log("ingresar");
            console.log("ingresar", usuario.value);
            let dat = {
                phone: usuario.value,
                password: contrasena.value
            };

            let data = await userService.LOGIN_USER(dat).then((response) => {
                console.log(response)
                if (response != null && !response.hasOwnProperty('error')) {
                    user.set(
                        response
                    );

                    if (response.user.role != 'cliente') {
                        router.push('/app/welcome');
                    } else {
                        router.push('/cl');
                    }
                    //if(response.user.role=='admin'){
                    //router.push('/app/welcome');

                    //this.$router.push('/app/'); 
                    //}
                } else {
                    alert("ocurrio un error");
                }
            });
        }
        const info_get = async () => {
            if (route.query.hasOwnProperty('cp')) {
                usuario.value = (route.query.cp).split('***')[1];
                contrasena.value = (route.query.cp).split('***')[0];
                visible.value=true;
            }

            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    info.set(
                        infov
                    );
                    infoData.value = (infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        }
        const registrar = async () => {
            console.log("registro", usuario.value);
            console.log(usuario.value, contrasena.value)
            if (usuario.value == '') {
                alert("Campo usuario es oblicatorio");
                return;
            }
            if (contrasena.value == '') {
                alert("Campo contrasena es oblicatorio");
                return;
            }
            let dat = {
                phone: usuario.value,
                password: contrasena.value,
                role: 'admin',
            };
            let data = await userService.REGISTRO_USER(dat).then((response) => {

                if (response != null) {
                    console.log(response);
                } else {
                    alert("ocurrio un error");
                }
            });
        }
        info_get()
        return {
            productPrice,
            registrar,
            ingresar,
            active,
            usuario,
            contrasena,
            config,
            user,
            router,
            route,
            infoData,
            visible

        };
    },
    data() {
        return {
           
            tipol: 'login'
            //usuario: '',
            //contrasena: '',
        }
    },
    mounted() {

        AOS.init({
            delay: 200,
            duration: 1200,
            once: false,
        })
    }
};
</script>

<style scoped>
.work-sans {
    font-family: 'Work Sans', sans-serif;
}

#menu-toggle:checked+#menu {
    display: block;
}

.hover\:grow {
    transition: all 0.3s;
    transform: scale(1);
}

.hover\:grow:hover {
    transform: scale(1.02);
}

.carousel-open:checked+.carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

#carousel-1:checked~.control-1,
#carousel-2:checked~.control-2,
#carousel-3:checked~.control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #000;
    /*Set to match the Tailwind colour you want the active one to be */
}
</style>
