<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
    <div x-show="selectedRows.length" class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow" />

    <Tabla :columns="headings" :rows="data" :showcols="false" :filtros="['nombre','precio','meses','tipo']" />

    <div v-if="showM" style="z-index:9000" class="overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none justify-center bg-black bg-opacity-50 items-center flex">
        <div class="relative  my-6 mx-auto md:w-8/12 xl:w-6/12 lg:w-6/12 bg-white">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">
                        {{ id==0?'Crear Metodo':'Editar Metodo' }}
                    </h3>
                    <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto h-96 overflow-y-scroll flex flex-wrap">
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Nombre
                        </span>
                        <input id="cantidad" v-model="nombre" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Comisión
                        </span>
                        <input id="cantidad" v-model="porcentage_comision" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="number" placeholder="Comisión">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Impuesto
                        </span>
                        <input id="cantidad" v-model="porcentage_impuestos" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="number" placeholder="Impuesto">
                    </div>
                   
                         
  
                         
              
       </div>
                      
                      
                     
                  </div>
                  <!--footer-->
                  <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                      <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                          Cancelar
                      </button>
                      <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                          Guardar
                      </button>
                  </div>
              </div>
        
      </div>
    
  </section>
  
</template>

  
  
<script>
import {
    emitter
} from "@/mitt.js";
import {
    useUserStore
} from '@/stores/user'
import metodos_pagos from "@/Services/metodos_pagos";

import Tabla from "@/components/Tabla.vue";
export default {
    components: {
        Tabla
    },
    mixins: [metodos_pagos],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '#',
                    show: true
                },
                {
                    key: 'nombre',
                    name: 'Nombre',
                    show: true
                },
                {
                    key: 'porcentage_comision',
                    name: 'Comisión',
                    show: true
                },
                {
                    key: 'porcentage_impuestos',
                    name: 'Impuesto',
                    show: true
                },

              
                {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],
            buscar: '',
            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
          
            nombre: '',
            porcentage_comision: 0,
            porcentage_impuestos: 0,

          

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {

        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        
        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.porcentage_comision = 0;
            this.porcentage_impuestos = 0;

        },
        edit(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.porcentage_comision = row.porcentage_comision;
            this.porcentage_impuestos = row.porcentage_impuestos;

            this.showM = true;
            this.actionModal = null;
        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await metodos_pagos.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {

        

           

            let dat = {
                id: this.id,
                nombre:this.nombre,
                porcentage_comision: this.porcentage_comision,
                porcentage_impuestos:this.porcentage_impuestos
            };

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await metodos_pagos.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await metodos_pagos.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await metodos_pagos.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
