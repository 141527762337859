// stores/counter.js
import { defineStore } from 'pinia'

export const useInfoStore = defineStore('info', {
  state: () => {
    return { 
       data:null
    
    }
  },
  persist: true,
  // could also be defined as
  // state: () => ({ count: 0 })
  getters: {
    getAll: (state) => {
      return () => state.data
    },

  },
  actions: {
    setval(name,data) {
      this.data[name]=data;
  
    },
    set(data) {
      this.data=data;
  
    },
    get() {
     return this.data
    },
  },
})