<template>
  <section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
     <!-- This is an example component -->
<div class="max-w-2xl mx-auto bg-white p-16">

	<form>
    <div class="grid gap-6 mb-6 lg:grid-cols-2">
        <div class="col-span-2 w-full flex flex-wrap content-center justify-center"> 
            <p class=" text-xl text-blue-600 text-center font-extrabold">Creacion de nueva rifa</p>

        </div>
       
        <div class="col-span-2">
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nombre</label>
            <input v-model="nombre" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nombre" required>
        </div>
        <div class="col-span-2">
            <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Descripcion</label>
            <textarea v-model="descripcion" type="text" id="last_name" class="h-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Descripcion" required></textarea>
        </div>
        <div>
            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"># Boletos</label>
            <input type="text" v-model="boletos" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="# Boletos" required>
        </div>  
        <div>
            <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Costo Boletos</label>
            <input type="tel" v-model="precio" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Costo Boletos" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required>
        </div>
       
    </div>
   
    <div class="flex items-start mb-6">
        <div class="flex items-center h-5">
        <input id="remember" type="checkbox" v-model="activo" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required>
        </div>
        <label for="remember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400">Rifa activa</label>
    </div>
    <button @click="guardar()" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Crear</button>
</form>


</div>
  </section>
  </template>
  
  <script type="ts">
  import {
      emitter
  } from "@/mitt.js";
  import {
      useUserStore
  } from '@/stores/user'
  import rifas from "@/Services/rifas";
  
  export default {
      components: {
          
      },
      mixins: [rifas],
      data() {
          const user = useUserStore()
  
          return {
            
            
              user: user.getAll(),
            id:0,
              nombre: '',
              descripcion: '',
              precio: '',
              boletos: '',
              activo:true
  
          }
  
      },
      mounted() {
  
      },
      created() {
          this.getData()
      },
      methods: {
          
          addRow() {
              this.showM = !this.showM;
              this.limpiar()
          },
          async onFileChange(e) {
              var files = e.target.files || e.dataTransfer.files;
              if (!files.length) return;
  
              const fileReader = new FileReader()
              fileReader.addEventListener('load', () => {
                  this.imageUrl = fileReader.result
              })
              fileReader.readAsDataURL(files[0])
              this.data_file = files[0];
              console.log(e)
  
          },
          onPickFile() {
              this.$refs.fileInput.click()
          },
          limpiar() {
  
              this.id = 0;
              this.nombre = '';
              this.descripcion = '';
              this.precio = '';
              this.boletos = '';
  
  
          },
          edit(row) {
              this.id = row.id;
              this.nombre = row.nombre;
              this.descripcion = row.descripcion;
              this.precio = row.precio;
              this.boletos = row.boletos;
  
  
              this.showM = true;
              this.actionModal = null;
          },
          async getData() {
              this.showM = false;
              this.actionModal = null;
              emitter.emit("loading", true)
  
              await rifas.GET(this.user.token).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
  
                      this.data = response
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  emitter.emit("loading", false)
              });
          },
          async guardar() {
  
            
  
              let dat = {
                  id: this.id,
                  nombre:this.nombre,
                  descripcion:this.descripcion,
                  precio:this.precio,
                  boletos:this.boletos,
                  activo:this.activo
              };
  
              if (this.id == 0) {
                  this.crear(dat)
              } else {
                  this.editar(dat)
              }
          },
          async editar(dat) {
              emitter.emit("loading", true)
  
              await rifas.EDITAR(dat).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
  
                      this.getData();
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  this.showM = false;
                  this.limpiar();
                  emitter.emit("loading", false)
              });
          },
          async crear(dat) {
              emitter.emit("loading", true)
  
              await rifas.CREAR(dat).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
                    this.limpiar();
                      this.getData();
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  this.showM = false;
                
                  emitter.emit("loading", false)
              });
          },
          async eliminar(id) {
              emitter.emit("loading", true)
              let dat = {
                  id: id,
  
              }
              await rifas.ELIMINAR(dat).then(response => {
  
                  if (response != null && !response.hasOwnProperty('error')) {
  
                      this.getData();
                      //this.$router.push('/app/'); 
                  } else {
  
                      // emitter.emit("alerta_show",response.msg);
  
                  }
                  this.actionModal = null
                  emitter.emit("loading", false)
              });
          },
  
      }
  }
  </script>
  