<template>
    <div class="loading-container">
        <div class="card">
            <div class="card__image"></div>
           
          </div>
      <div class="loading-overlay" >
        <div class="loading-spinner"></div>
      </div>
      <slot></slot>
    </div>
  </template>
  
  <style scoped>
  .loading-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }




  .app {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
  
  .card {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left:0;
    margin-right:0;
    background: #fff;
   
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  
  .card__image {
    background: linear-gradient(110deg, #e2dfdf 8%, #f5f5f5 18%, #ececec 33%);
  
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
  }
  
  .card__image {
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
 
  </style>
  