// stores/counter.js
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return { 
        usuario: {token:'token',historias:[]},
       
        token:'',
    
    }
  },
  persist: true,
  // could also be defined as
  // state: () => ({ count: 0 })
  getters: {
    getAll: (state) => {
      return () => state.usuario
    },
    getToken: (state) => {
      return () => state.token
    },
   
  },
  actions: {
    set(data) {
      this.token=data.token;
      this.usuario=data.user;
    
    },
    setInfo(data){
      this.usuario.info_users=data
    },
    setPortadaImg(data){
      this.usuario.info_users.portada_img=data
    },
    setProfileImg(data){
      this.usuario.info_users.img=data
    },
    setHistorias(data){
      this.usuario.historias=data;
      },
    setHistoria(data){
    this.usuario.historias.push(data)
    },
    limpiar(data) {
      this.token='';
      this.usuario={token:'token',historias:[]};
     
    },
    get() {
     return this.usuario
    },
  }, 
  
})